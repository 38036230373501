/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { API_BASE_URL } from '../../environment';
import Loading from '../../components/Loading';

import * as sessionService from '../../services/sessions';

import stylesLayout from '../../common/layout.module.scss';
import SessionsTable from '../../components/SessionsTable';

const SessionsModule = (
) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [sessions, setSessions] = useState([]);

  const loadSessions = async () => {
    setLoading(true);
    const rsp = await sessionService.all();
    if (rsp && rsp.status) {
      setSessions(rsp.data);
    }
    setLoading(false);
  };

  const onAction = (action, hash, customerHash) => {
    switch (action) {
      case 'view':
        history.push(`/sesion/${hash}/${customerHash}`);
        break;
      default:
    }
  };

  useEffect(() => {
    loadSessions();
  }, []);

  if (loading) {
    return <div className={stylesLayout.page}><Loading /></div>;
  }

  // const stepsStore = getStepsStore(store);
  // const pendingStepsStore = (stepsStore.filter((x) => !x.completed));
  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>
        Sesiones
        <div className={stylesLayout.secAction}>
          <CSVLink
            filename="sesiones.csv"
            data={sessions.map((e) => ({
              nombre: e.name,
              email: e.email,
              telefono: e.phone,
              nacimiento: e.birthdate ? moment(e.birthdate).format('YYYY-MM-DD') : '',
              sol: e.sun,
              luna: e.moon,
              ascendente: e.ascendant,
              fecha_sesion: e.date ? moment(e.date).format('YYYY-MM-DD') : '',
              mes_sesion: e.date ? moment(e.date).format('YYYY-MM') : '',
              tipo: e.type_tr,
            }))}
          >
            <Button type="secondary">
              <FontAwesomeIcon icon={faDownload} style={{ marginRight: 5 }} />
              Exportar como CSV

            </Button>
          </CSVLink>
          {' '}
          <Button onClick={() => {
            window.open(`${API_BASE_URL}/sessions/xlsx?token=${localStorage.getItem('token')}`);
          }}
          >
            <FontAwesomeIcon icon={faDownload} style={{ marginRight: 10 }} />
            Exportar como Excel

          </Button>
        </div>
      </div>
      <div className={stylesLayout.content}>
        <SessionsTable
          items={sessions}
          onAction={onAction}
        />
      </div>
    </div>
  );
};

SessionsModule.propTypes = {
};

export default SessionsModule;
