/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// import moment from 'moment';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Loading from '../../components/Loading';

import * as itemService from '../../services/items';

import stylesLayout from '../../common/layout.module.scss';
import ItemsTable from '../../components/ItemsTable';

const ItemsModule = (
) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);

  const loadItems = async () => {
    setLoading(true);
    const rsp = await itemService.all();
    if (rsp && rsp.status) {
      setItems(rsp.data);
    }
    setLoading(false);
  };

  const onAction = (action, hash) => {
    switch (action) {
      case 'view':
        history.push(`/item/${hash}`);
        break;
      default:
    }
  };

  useEffect(() => {
    loadItems();
  }, []);

  if (loading) {
    return <div className={stylesLayout.page}><Loading /></div>;
  }

  // const stepsStore = getStepsStore(store);
  // const pendingStepsStore = (stepsStore.filter((x) => !x.completed));
  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>
        Items
        <div className={stylesLayout.secAction}>
          <Button onClick={() => { history.push('/item/nuevo'); }}>
            <FontAwesomeIcon icon={faPlus} style={{ marginRight: 10 }} />
            Agregar
          </Button>
        </div>
      </div>
      <div className={stylesLayout.content}>
        <ItemsTable
          items={items}
          onAction={onAction}
        />
      </div>
    </div>
  );
};

ItemsModule.propTypes = {
};

export default ItemsModule;
