export default (client, defaultParams) => ({
  get: (hash) => (
    client(`/item/${hash}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  resyncFiles: (hash) => (
    client(`/item/${hash}/resync_files`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    })
  ),
  all: () => (
    client('/items', {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  update: (hash, data) => (
    client(`/item/${hash}`, {
      method: 'PUT',
      data,
      params: {
        ...defaultParams,
      },
    })
  ),
  create: (data) => (
    client('/items', {
      method: 'POST',
      data,
      params: {
        ...defaultParams,
      },
    })
  ),

  getMessage: (k) => {
    const messages = {
    };
    return (messages[k] || k);
  },
});
