/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faUpload } from '@fortawesome/free-solid-svg-icons';
import UploaderFile from '../UploaderFile';
import * as filesService from '../../services/files';
import { humanFileSize } from '../../helpers/utils';

import { API_BASE_URL } from '../../environment';

import stylesForm from '../../common/form.module.scss';
import stylesLayout from '../../common/layout.module.scss';

import FileRow from '../FileRow';

import styles from './index.module.scss';
import CopyWithMessage from '../CopyWithMessage';

const FilesListAndUpload = ({
  files,
  folderId,
  onUploadComplete,
  onDeleteComplete,
  onSaveChangeTitleComplete,
}) => (
  <>
    <div className={stylesForm.header}>
      Archivos
    </div>
    <div style={{
      verticalAlign: 'top',
      marginTop: -20,
      marginBottom: 40,
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      gap: 10,
      maxWidth: '70%',
      lineHeight: '150%',
      width: 'auto',
    }}
    >
      <div
        style={{
          display: 'inline-block', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '100%',
        }}
      >
        <a
          href={`https://drive.google.com/drive/folders/${folderId}`}
          target="_ac_gdrive"
          className={stylesLayout.link}
          role="button"
          tabIndex={0}
          style={{
            textOverflow: 'ellipsis',
          }}
        >
          {`https://drive.google.com/drive/folders/${folderId}`}
        </a>
      </div>

      <div>
        <CopyWithMessage value={`https://drive.google.com/drive/folders/${folderId}`} />
      </div>
    </div>
    <div>
      <table className={styles.tableFiles}>
        {files && files.map((file) => (
          <FileRow
            file={file}
            filesService={filesService}
            onSaveChangeTitle={async (title) => {
              await filesService.changeTitle(file.id, title);
              onSaveChangeTitleComplete();
            }}
            onDelete={async () => {
              if (confirm('¿Confirmas eliminar este archivo?')) {
                const rsp = await filesService.remove(file.id);
                if (rsp && rsp.status) {
                  onDeleteComplete();
                }
              }
            }}
          />
        ))}

      </table>
      <br />
      <div className={styles.uploader}>
        <UploaderFile
          className={styles.uploader}
          maxSizeMB={2048}
          multiple
          accept={false}
          uploadUrl={`${API_BASE_URL}/gdrive/folder/${folderId}/upload`}
          render={({
            uploading, progress,
          }) => (
            <>
              {!uploading && (
                <div className={styles.uploaderInner}>
                  <FontAwesomeIcon icon={faUpload} style={{ marginRight: 10 }} />
                  {' '}
                  Haz click o arrastra un nuevo archivo aquí
                </div>
              )}
              {uploading && progress && progress.map((p) => (
                <div className={styles.fileDetail}>
                  <div className={styles.fileDetailProgress} style={{ width: `${p.percent}%` }} />
                  <div className={styles.fileDetailInner}>
                    {p.percent >= 100
                      ? <FontAwesomeIcon icon={faCheck} color="#093" style={{ marginRight: 10, color: '#093' }} />
                      : <FontAwesomeIcon icon={faUpload} color="#999" style={{ marginRight: 10, color: '#999' }} />}
                    <div className={styles.fileDetailName}>
                      <span style={{
                        display: 'inline-block', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '80%',
                      }}
                      >
                        {p.name}

                      </span>
                      {p.percent >= 100 && <span> - Guardando...</span>}
                    </div>
                    <div className={styles.fileDetailSize}>
                      {humanFileSize((p.size * p.percent) / 100)}
                      {' '}
                      de
                      {' '}
                      {humanFileSize(p.size)}
                    </div>
                    <div className={styles.fileDetailPercent}>
                      {parseInt(p.percent, 10)}
                      %
                    </div>

                  </div>

                </div>
              ))}
            </>
          )}
          onUpload={(data) => {
            onUploadComplete(data);
          }}
        />
      </div>
    </div>
  </>
);
FilesListAndUpload.defaultProps = {
  onUploadComplete: () => { },
  onDeleteComplete: () => { },
  onSaveChangeTitleComplete: () => { },
};

FilesListAndUpload.propTypes = {
  files: PropTypes.array.isRequired,
  folderId: PropTypes.string.isRequired,
  onUploadComplete: PropTypes.func,
  onDeleteComplete: PropTypes.func,
  onSaveChangeTitleComplete: PropTypes.func,
};

export default FilesListAndUpload;
