/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import {
  Row, Col,
  Form, Input,
  Button,
  Alert,
  DatePicker,
  Select,
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import Loading from '../../components/Loading';
import FilesListAndUpload from '../../components/FilesListAndUpload';

import * as lessonService from '../../services/lessons';

// import styles from './index.module.scss';
import stylesLayout from '../../common/layout.module.scss';
import stylesForm from '../../common/form.module.scss';

const { Option } = Select;

const LessonModule = (
) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { hash, courseId } = useParams();
  const [loading, setLoading] = useState(true);
  const [lesson, setLesson] = useState([]);

  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);

  const loadLesson = async (showLoader) => {
    if (hash === 'nueva') {
      setLoading(false);
      return;
    }
    if (showLoader) {
      setLoading(true);
    }
    const rsp = await lessonService.get(hash);
    if (rsp && rsp.status) {
      setLesson(rsp.data);
    }
    setLoading(false);
  };

  const onSaveItem = async (values) => {
    setSaving(true);
    setError(null);
    const s = {
      ...lesson,
      ...values,
    };
    if (hash === 'nueva') {
      s.course_id = courseId;
      const rsp = await lessonService.create(s);
      setSaving(false);
      if (rsp && rsp.status) {
        history.replace(`/clase/${rsp.data.hash}`);
      }

      return;
    }

    const rsp = await lessonService.update(s.hash, s);
    if (rsp && !rsp.status) {
      setError(rsp.msg);
    }
    if (rsp && rsp.status) {
      setSaved(true);
    }
    if (rsp && !rsp.status) {
      setError(rsp.msg);
    }
    setSaving(false);
  };

  const onChangeItem = () => {
    const s = {
      ...lesson,
      ...form.getFieldsValue(),
    };
    setSaved(false);
    setLesson(s);
  };
  const onChangeField = (field, value) => {
    const s = {
      ...lesson,
      [field]: value,
    };
    setSaved(false);
    setLesson(s);
  };

  useEffect(() => {
    loadLesson(true);
  }, [hash]);

  if (loading) {
    return <div className={stylesLayout.page}><Loading /></div>;
  }
  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.breadcrumb}>
        <a onClick={() => { history.goBack(); }}>{lesson.course ? lesson.course.name : 'Volver'}</a>
        {' '}
        &raquo;
      </div>
      <div className={stylesLayout.title}>
        {hash === 'nueva' ? 'Nueva clase' : lesson.name}
      </div>
      <div className={stylesLayout.meta} />

      <div className={`${stylesLayout.content} ${stylesForm.form}`}>
        {error
          && (
            <div className={stylesForm.error}>
              <Alert message={error} type="error" />
            </div>
          )}

        <Form
          name="basic"
          form={form}
          labelAlign="left"
          initialValues={lesson}
          layout="horizontal"
          {...{
            labelCol: { span: 3 },
            wrapperCol: { span: 21 },
          }}
          onFinish={onSaveItem}
          onValuesChange={onChangeItem}
          size="large"
        >

          <Row>
            <Col xs={24}>
              <Form.Item
                label="Nombre"
                name="name"
                rules={[{ required: true, message: 'El nombre es requerido' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form.Item
                label="Detalles"
                name="description"
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form.Item
                label="Tipo"
                name="type"
              >
                <Select placeholder="Selecciona..." style={{ width: '100%' }}>
                  <Option value="NORMAL">Normal</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form.Item
                label="Status"
                name="status"
              >
                <Select placeholder="Selecciona..." style={{ width: '100%' }}>
                  <Option value="DRAFT">Borrador</Option>
                  <Option value="ACTIVE">Activo</Option>
                  <Option value="INACTIVE">Inactivo</Option>
                  <Option value="DELETED">Eliminado</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form.Item
                label="Fecha inicio"
              >
                <DatePicker
                  placeholder="Elige fecha..."
                  defaultValue={lesson.start_date && moment(lesson.start_date)}
                  onChange={(e) => { onChangeField('start_date', e); }}
                  format="YYYY-MM-DD"
                  style={{ width: '100%', maxWidth: 300 }}
                />
              </Form.Item>

            </Col>
          </Row>
          <Row style={{ marginBottom: 30 }}>
            <Col offset={4} span={20} xs={{ offset: 0, span: 24 }}>
              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={saving}>
                  Guardar
                </Button>

                {saving && <span className={stylesForm.saveMessage}>Guardando...</span>}
                {!saving && saved && (
                  <span className={`${stylesForm.saveMessage} ${stylesForm.success}`}>
                    <FontAwesomeIcon icon={faCheck} />
                    {' '}
                    Guardado
                  </span>
                )}
              </Form.Item>
            </Col>
          </Row>
          {hash !== 'nueva' && lesson.data?.gdrive_folder_id && (
            <>
              <FilesListAndUpload
                files={lesson.files}
                folderId={lesson.data?.gdrive_folder_id}
                onUploadComplete={() => { loadLesson(false); }}
                onDeleteComplete={() => { loadLesson(false); }}
                onSaveChangeTitleComplete={() => { loadLesson(false); }}
              />
            </>
          )}
        </Form>
      </div>
    </div>
  );
};

LessonModule.propTypes = {
};

export default LessonModule;
