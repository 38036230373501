import apiClient from '../api';
import { executeAndWaitResponse } from './service';

export const all = async () => executeAndWaitResponse(
  () => apiClient.items.all(),
);

export const get = async (hash) => executeAndWaitResponse(
  () => apiClient.items.get(hash),
);
export const update = async (hash, data) => executeAndWaitResponse(
  () => apiClient.items.update(hash, data),
);
export const create = async (data) => executeAndWaitResponse(
  () => apiClient.items.create(data),
);
export const remove = async (hash) => executeAndWaitResponse(
  () => apiClient.items.update(hash, { status: 'DELETED' }),
);

export const resyncFiles = async (hash) => executeAndWaitResponse(
  () => apiClient.items.resyncFiles(hash),
);

export default {
  all,
  create,
  update,
  remove,
  get,
  resyncFiles,
};
