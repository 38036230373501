export default (client, defaultParams) => ({
  get: (hash) => (
    client(`/session/${hash}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  all: () => (
    client('/sessions', {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  xlsx: () => (
    client('/sessions/xlsx', {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  update: (hash, data) => (
    client(`/session/${hash}`, {
      method: 'PUT',
      data,
      params: {
        ...defaultParams,
      },
    })
  ),
  sendAccessEmail: (hash, email) => (
    client(`/session/${hash}/send_access_email`, {
      method: 'PUT',
      data: { email },
      params: {
        ...defaultParams,
      },
    })
  ),
  create: (data) => (
    client('/sessions', {
      method: 'POST',
      data,
      params: {
        ...defaultParams,
      },
    })
  ),

  getMessage: (k) => {
    const messages = {
    };
    return (messages[k] || k);
  },
});
