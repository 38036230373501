/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Row, Col,
  Form, Input,
  Button,
  Alert,
  Select,
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import Loading from '../../components/Loading';

import * as libraryService from '../../services/libraries';
import * as itemService from '../../services/items';

// import styles from './index.module.scss';
import stylesLayout from '../../common/layout.module.scss';
import stylesForm from '../../common/form.module.scss';

const { Option } = Select;

const LibraryModule = (
) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { hash } = useParams();
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [itemsInLibrary, setItemsInLibrary] = useState([]);
  const [library, setLibrary] = useState([]);

  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);

  const loadItems = async () => {
    const rsp = await itemService.all();
    if (rsp && rsp.status) {
      setItems(rsp.data);
    }
  };

  const loadLibrary = async (showLoader) => {
    if (hash === 'nueva') {
      setLoading(false);
      return;
    }
    if (showLoader) {
      setLoading(true);
    }
    const rsp = await libraryService.get(hash);
    if (rsp && rsp.status) {
      setLibrary(rsp.data);
      console.log('rsp.data', rsp.data);
      setItemsInLibrary(rsp.data.items);
    }
    setLoading(false);
  };

  const onSaveItem = async (values) => {
    setSaving(true);
    setError(null);
    const s = {
      ...library,
      ...values,
      items: itemsInLibrary,
    };
    console.log('s', s);

    if (hash === 'nueva') {
      const rsp = await libraryService.create(s);
      setSaving(false);
      if (rsp && rsp.status) {
        history.replace(`/biblioteca/${rsp.data.hash}`);
      }

      return;
    }

    const rsp = await libraryService.update(s.hash, s);
    if (rsp && !rsp.status) {
      setError(rsp.msg);
    }
    if (rsp && rsp.status) {
      setSaved(true);
    }
    if (rsp && !rsp.status) {
      setError(rsp.msg);
    }
    setSaving(false);
  };

  const onChangeItem = () => {
    const s = {
      ...library,
      ...form.getFieldsValue(),
    };
    setSaved(false);
    setLibrary(s);
  };
  // eslint-disable-next-line no-unused-vars
  const onChangeField = (field, value) => {
    const s = {
      ...library,
      [field]: value,
    };
    setSaved(false);
    setLibrary(s);
  };

  useEffect(() => {
    Promise.all([
      loadItems(true),
      loadLibrary(true),
    ]);
  }, [hash]);

  if (loading) {
    return <div className={stylesLayout.page}><Loading /></div>;
  }
  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.breadcrumb}>
        <a onClick={() => { history.push('/bibliotecas'); }}>Bibliotecas</a>
        {' '}
        &raquo;
      </div>
      <div className={stylesLayout.title}>
        {hash === 'nueva' ? 'Nueva biblioteca' : library.name}
      </div>
      <div className={stylesLayout.meta} />

      <div className={`${stylesLayout.content} ${stylesForm.form}`}>
        {error
          && (
            <div className={stylesForm.error}>
              <Alert message={error} type="error" />
            </div>
          )}

        <Form
          name="basic"
          form={form}
          labelAlign="left"
          initialValues={library}
          layout="horizontal"
          {...{
            labelCol: { span: 3 },
            wrapperCol: { span: 21 },
          }}
          onFinish={onSaveItem}
          onValuesChange={onChangeItem}
          size="large"
        >

          <Row>
            <Col xs={24}>
              <Form.Item
                label="Nombre"
                name="name"
                rules={[{ required: true, message: 'El nombre es requerido' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form.Item
                label="Descripción"
                name="description"
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form.Item
                label="Status"
                name="status"
                initialValue="INACTIVE"
              >
                <Select placeholder="Selecciona..." style={{ width: '100%' }}>
                  <Option value="ACTIVE">Activa</Option>
                  <Option value="INACTIVE">Inactiva</Option>
                  <Option value="DELETED">Eliminada</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form.Item
                label="Items en la biblioteca"
              >
                <Select
                  placeholder="Selecciona..."
                  mode="multiple"
                  style={{ width: '100%' }}
                  options={items && items.map((x) => ({ label: `${x.title} - ${x.status_tr}`, value: x.id }))}
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  defaultValue={itemsInLibrary}
                  onChange={(v) => setItemsInLibrary(v)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginBottom: 30 }}>
            <Col offset={4} span={20} xs={{ offset: 0, span: 24 }}>
              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={saving}>
                  Guardar
                </Button>

                {saving && <span className={stylesForm.saveMessage}>Guardando...</span>}
                {!saving && saved && (
                  <span className={`${stylesForm.saveMessage} ${stylesForm.success}`}>
                    <FontAwesomeIcon icon={faCheck} />
                    {' '}
                    Guardado
                  </span>
                )}
              </Form.Item>
            </Col>
          </Row>

        </Form>
      </div>
    </div>
  );
};

LibraryModule.propTypes = {
};

export default LibraryModule;
