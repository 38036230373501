import apiClient from '../api';
import { executeAndWaitResponse } from './service';

export const get = async (hash) => executeAndWaitResponse(
  () => apiClient.lessons.get(hash),
);
export const update = async (hash, data) => executeAndWaitResponse(
  () => apiClient.lessons.update(hash, data),
);
export const create = async (data) => executeAndWaitResponse(
  () => apiClient.lessons.create(data),
);

export default {
  get,
  update,
  create,
};
