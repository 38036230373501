import apiClient from '../api';
import { executeAndWaitResponse } from './service';

export const all = async () => executeAndWaitResponse(
  () => apiClient.libraries.all(),
);

export const get = async (hash) => executeAndWaitResponse(
  () => apiClient.libraries.get(hash),
);
export const update = async (hash, data) => executeAndWaitResponse(
  () => apiClient.libraries.update(hash, data),
);
export const create = async (data) => executeAndWaitResponse(
  () => apiClient.libraries.create(data),
);
export const remove = async (hash) => executeAndWaitResponse(
  () => apiClient.libraries.update(hash, { status: 'DELETED' }),
);

export default {
  all,
  create,
  update,
  remove,
  get,
};
