import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setUser as setUserAction } from '../Auth/actions';

import {
  logout,
} from '../../helpers/storage';

const LogoutModule = ({
  setUser,
  // eslint-disable-next-line no-unused-vars
  user,
}) => {
  const history = useHistory();
  useEffect(() => {
    (async () => {
      console.log('Logging out...');
      setUser({});
      await logout();
      console.log('Done');
      history.push('/login');
    })();
  }, []);

  return null;
};

LogoutModule.defaultProps = {
  user: null,
};

LogoutModule.propTypes = {
  setUser: PropTypes.func.isRequired,
  user: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUserAction(user)),
});

export default connect(
  null,
  mapDispatchToProps,
)(LogoutModule);
