/* eslint-disable no-restricted-syntax */
import React, { useEffect } from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';

import pjson from '../package.json';
import { getAppVersion, setAppVersion } from './helpers/storage';

import BlankLayout from './layouts/BlankLayout';
import AdminLayout from './layouts/AdminLayout';

import GiftcardsModule from './modules/GiftcardsModule';
import SessionsModule from './modules/SessionsModule';
import CustomersModule from './modules/CustomersModule';
import GiftcardModule from './modules/GiftcardModule';
import SessionModule from './modules/SessionModule';
import CustomerModule from './modules/CustomerModule';
import CoursesModule from './modules/CoursesModule';
import CourseModule from './modules/CourseModule';
import UsersModule from './modules/UsersModule';
import UserModule from './modules/UserModule';
import RegisterModule from './modules/RegisterModule';
import LessonModule from './modules/LessonModule';
import LoginModule from './modules/LoginModule';
import LogoutModule from './modules/LogoutModule';
import LibrariesModule from './modules/LibrariesModule';
import LibraryModule from './modules/LibraryModule';
import ItemsModule from './modules/ItemsModule';
import ItemModule from './modules/ItemModule';

const Routes = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search.slice(1));
    let currentParams = {};
    try {
      currentParams = JSON.parse(localStorage.getItem('__parameters'));
      // eslint-disable-next-line no-empty
    } catch (e) {
    }
    const parameters = {
      ...currentParams,
    };
    // eslint-disable-next-line guard-for-in
    for (const [k, v] of params) {
      parameters[k] = v;
    }
    localStorage.setItem('__parameters', JSON.stringify(parameters));
    const storedAppVersion = getAppVersion();
    setAppVersion(pjson.version);
    console.log('storedAppVersion', storedAppVersion, pjson.version);
    if (storedAppVersion !== pjson.version && !['/login', '/logout'].includes(window.location.pathname)) {
      window.location = '/logout';
    }
  }, []);
  return (
    <BrowserRouter>
      <Switch>
        <BlankLayout exact path="/login" component={LoginModule} />
        <BlankLayout exact path="/logout" component={LogoutModule} />
        <AdminLayout exact path="/" redirectTo="/clientes" />
        <AdminLayout exact path="/bibliotecas" component={LibrariesModule} />
        <AdminLayout exact path="/biblioteca/:hash" component={LibraryModule} />
        <AdminLayout exact path="/items" component={ItemsModule} />
        <AdminLayout exact path="/item/:hash" component={ItemModule} />
        <AdminLayout exact path="/sesiones" component={SessionsModule} />
        <AdminLayout exact path="/sesion/:hash/:customerHash" component={SessionModule} />
        <AdminLayout exact path="/clientes" component={CustomersModule} />
        <AdminLayout exact path="/cliente/:hash" component={CustomerModule} />
        <AdminLayout exact path="/giftcards" component={GiftcardsModule} />
        <AdminLayout exact path="/giftcard/:hash" component={GiftcardModule} />
        <AdminLayout exact path="/cursos" component={CoursesModule} />
        <AdminLayout exact path="/curso/:hash" component={CourseModule} />
        <AdminLayout exact path="/usuarios" component={UsersModule} />
        <AdminLayout exact path="/usuario/crear" component={RegisterModule} />
        <AdminLayout exact path="/usuario/:id" component={UserModule} />
        <AdminLayout exact path="/clase/:hash" component={LessonModule} />
        <AdminLayout exact path="/clase/:hash/:courseId" component={LessonModule} />
      </Switch>
    </BrowserRouter>
  );
};

Routes.defaultProps = {
};

Routes.propTypes = {
};

export default Routes;
