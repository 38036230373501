/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopy,
} from '@fortawesome/free-solid-svg-icons';

import moment from 'moment';
import {
  notification, Button, Input, Space,
} from 'antd';
import styles from './index.module.scss';
import Loading from '../../components/Loading';

import * as usersService from '../../services/users';

import stylesLayout from '../../common/layout.module.scss';
import CopyWithMessage from '../../components/CopyWithMessage';

const UserModule = () => {
  const { id: idParam } = useParams();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [newName, setNewName] = useState('');
  const [newPhone, setNewPhone] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);

  const loadUser = async (id, showNotification) => {
    if (showNotification) {
      notification.info({
        message: 'Actualizando cliente...',
        placement: 'top',
        key: 'result',
        duration: 0,
      });
    }
    const rsp = await usersService.get(id);
    if (rsp && rsp.status) {
      const newUser = rsp.data;
      if (user?.events) {
        newUser.events = user.events;
      }
      if (user?.user_events) {
        newUser.user_events = user.user_events;
      }

      setUser(newUser);
      setNewPhone(newUser.phone || ' ');
      setNewName(newUser.name || ' ');
      if (showNotification) {
        notification.success({
          message: 'Usuario actualizado.',
          placement: 'top',
          key: 'result',
        });
      }
    } else if (showNotification) {
      notification.error({
        message: 'Error al actualizar cliente.',
        placement: 'top',
        key: 'result',
      });
    }
    setLoading(false);
  };

  const changePhone = async () => {
    notification.destroy('in_progress');
    notification.destroy('result');
    notification.info({
      message: 'Actualizando teléfono...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await usersService.updateField(user.id, 'phone', newPhone);
    if (rsp?.status) {
      loadUser(user.id);
      notification.success({
        message: 'Teléfono cambiado.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al cambiar teléfono.',
        placement: 'top',
        key: 'result',
      });
    }
  };

  const changeName = async () => {
    notification.destroy('in_progress');
    notification.destroy('result');
    notification.info({
      message: 'Actualizando nombre...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await usersService.updateField(user.id, 'name', newName);
    if (rsp?.status) {
      loadUser(user.id);
      notification.success({
        message: 'Nombre cambiado.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al cambiar nombre.',
        placement: 'top',
        key: 'result',
      });
    }
  };

  const changePassword = async () => {
    notification.destroy('in_progress');
    notification.destroy('result');
    notification.info({
      message: 'Actualizando contraseña...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await usersService.adminChangePassword(user.id, newPassword);
    if (rsp?.status) {
      loadUser(user.id);
      notification.success({
        message: 'Contraseña cambiada.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al cambiar contraseña.',
        placement: 'top',
        key: 'result',
      });
    }
  };
  const onChangeStatus = async (newStatus) => {
    notification.destroy('in_progress');
    notification.destroy('result');
    notification.info({
      message: 'Actualizando estado...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await usersService.updateField(user.id, 'status', newStatus);
    if (rsp?.status) {
      loadUser(user.id);
      notification.success({
        message: 'Estado cambiado.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al cambiar estado.',
        placement: 'top',
        key: 'result',
      });
    }
  };

  useEffect(() => {
    loadUser(idParam);
  }, [idParam]);

  if (loading) {
    return (
      <div className={stylesLayout.page}>
        <div className={stylesLayout.loading}>
          <Loading />
        </div>
      </div>
    );
  }
  if (!user) {
    return (
      <div className={stylesLayout.page}>
        <div className={stylesLayout.loading}>
          Usuario no encontrado
        </div>
      </div>
    );
  }

  return (
    <div className={styles.cnt}>
      <div className={stylesLayout.page}>
        <div className={stylesLayout.breadcrumb}>
          <Link to="/usuarios">Usuarios</Link>
          {' '}
          &raquo;
        </div>
        <div className={stylesLayout.title}>
          #
          {user.id}
          {' '}
          -
          {' '}
          {user.email}
          <br />
          <small>
            {user.name}
            {' '}
            (
            {user.status_tr}
            )
          </small>
        </div>
        <div className={stylesLayout.tools}>
          {user.status === 'ACTIVE'
            && (
              <Button onClick={() => onChangeStatus('INACTIVE')}>Desactivar usuario</Button>

            )}
          {user.status === 'INACTIVE'
            && (
              <Button onClick={() => onChangeStatus('ACTIVE')}>Activar usuario</Button>
            )}
        </div>
        <div className={[stylesLayout.content, styles.main].join(' ')}>

          <div className={styles.info}>
            <table>
              <tr>
                <td width="25%">Nombre</td>
                <td>
                  <Space direction="horizontal" align="start">

                    <div>
                      <Input
                        value={newName}
                        autoComplete="off"
                        id="user-name"
                        onChange={(e) => setNewName(e.target.value)}
                        style={{ width: 300 }}
                      />
                    </div>

                    <Button onClick={changeName}>Cambiar Nombre</Button>
                  </Space>
                </td>
              </tr>
              <tr>
                <td width="25%">Teléfono</td>
                <td>
                  <Space direction="horizontal" align="start">

                    <div>
                      <Input
                        value={newPhone}
                        autoComplete="off"
                        id="user-tel"
                        onChange={(e) => setNewPhone(e.target.value)}
                        style={{ width: 300 }}
                      />
                    </div>

                    <Button onClick={changePhone}>Cambiar Teléfono</Button>
                  </Space>
                </td>
              </tr>
              <tr>
                <td width="25%">Contraseña</td>
                <td>
                  <Space direction="horizontal">
                    <Input.Password
                      autoComplete="new-password"
                      value={newPassword}
                      id="user-new_p"
                      onChange={(e) => setNewPassword(e.target.value)}
                      style={{ width: 300 }}
                      visibilityToggle={{
                        visible: passwordVisible,
                        onVisibleChange: setPasswordVisible,
                      }}
                    />
                    {!!newPassword && (
                      <CopyWithMessage
                        inline
                        value={newPassword}
                        messageCopied="Contraseña copiada!"
                        messageTime={2000}
                      >
                        <FontAwesomeIcon icon={faCopy} style={{ fontSize: 16 }} />
                      </CopyWithMessage>
                    )}
                    <Button onClick={changePassword}>Cambiar Contraseña</Button>
                  </Space>
                  <br />
                  <a
                    onClick={() => {
                      setPasswordVisible(true);
                      setNewPassword(String(parseInt(100000 + Math.random() * 999999, 10)));
                    }}
                  >
                    Generar contraseña aleatoria
                  </a>
                </td>
              </tr>
              <tr>
                <td width="25%">Creado / Modificado</td>
                <td>
                  {moment.utc(user.created_at).local().format('YYYY-MM-DD HH:mm:ss')}
                  {' '}
                  /
                  {' '}
                  {moment.utc(user.updated_at).local().format('YYYY-MM-DD HH:mm:ss')}
                </td>
              </tr>

            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

UserModule.propTypes = {
};

export default UserModule;
