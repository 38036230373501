/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useHistory, Route, Link } from 'react-router-dom';
// import { usePosition } from 'use-position';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';

import pjson from '../../../package.json';
import styles from './index.module.scss';

import { hasAccess } from '../../helpers/utils';

import Loading from '../../components/Loading';

import stylesLayout from '../../common/layout.module.scss';

const AdminLayout = ({
  title,
  back,
  user,
  redirectTo,
  requiredPermissions,
  component: Component,
  ...props
}) => {
  // const position = usePosition(true, { enableHighAccuracy: true });

  const history = useHistory();
  const [loading, setLoading] = useState(true);

  /* useEffect(() => {
    localStorage.setItem('__position', JSON.stringify(position));
  }, [position]); */
  useEffect(async () => {
    setLoading(true);
    setLoading(false);
  }, []);
  useEffect(() => {
    if (redirectTo) {
      history.push(redirectTo);
    }
  }, [redirectTo]);

  useEffect(() => {
    if (!user || !user.id) {
      history.push('/login');
    }
    return null;
  }, [user]);

  if (loading || !user) {
    return <div className={stylesLayout.page}><Loading /></div>;
  }

  if (!user.is_internal) {
    return (
      <div className={stylesLayout.page} style={{ padding: 100, textAlign: 'center' }}>
        No tienes acceso a esta sección
        <br />
        <br />
        <Link to="/logout">Salir</Link>
      </div>
    );
  }

  if (!Component) {
    return null;
  }

  return (
    <Route
      {...props}
      render={(matchProps) => (
        <div className={styles.root}>
          <div className={styles.container}>
            <div className={styles.sidebar}>
              <div className={styles.inner}>
                <div className={styles.logo}>
                  <Link to="/">
                    <img src="/logo/Almaceleste-01.png" alt="Alma Celeste" />
                  </Link>
                </div>
                <div className={styles.user}>
                  <div className={styles.name}>
                    {user.email}
                  </div>
                  <div className={styles.logout}>
                    <Link to="/logout">
                      <FontAwesomeIcon icon={faSignOutAlt} />
                      {' '}
                      Salir
                    </Link>

                  </div>
                </div>
                <div className={styles.menu}>
                  <div className={styles.options}>
                    {(hasAccess(user, 'customers'))
                      && (
                        <div className={`${styles.option} ${props.path.match('/cliente') ? styles.selected : null}`}>
                          <Link to="/clientes">
                            <FontAwesomeIcon icon={faAngleRight} />
                            Clientes
                          </Link>
                        </div>
                      )}
                    {(hasAccess(user, 'sessions'))
                      && (
                        <div className={`${styles.option} ${props.path.match('/sesion') ? styles.selected : null}`}>
                          <Link to="/sesiones">
                            <FontAwesomeIcon icon={faAngleRight} />
                            Sesiones
                          </Link>
                        </div>
                      )}
                    {(hasAccess(user, 'giftcards'))
                      && (
                        <div className={`${styles.option} ${props.path.match('/giftcard') ? styles.selected : null}`}>
                          <Link to="/giftcards">
                            <FontAwesomeIcon icon={faAngleRight} />
                            Giftcards
                          </Link>
                        </div>
                      )}
                    {(hasAccess(user, 'courses'))
                      && (
                        <div className={`${styles.option} ${(props.path.match('/curso') || props.path.match('/clase')) ? styles.selected : null}`}>
                          <Link to="/cursos">
                            <FontAwesomeIcon icon={faAngleRight} />
                            Cursos
                          </Link>
                        </div>

                      )}
                    {(hasAccess(user, 'libraries'))
                      && (
                        <div className={`${styles.option} ${(props.path.match('/biblioteca')) ? styles.selected : null}`}>
                          <Link to="/bibliotecas">
                            <FontAwesomeIcon icon={faAngleRight} />
                            Bibliotecas
                          </Link>
                        </div>

                      )}
                    {(hasAccess(user, 'libraries'))
                      && (
                        <div className={`${styles.option} ${(props.path.match('/items')) ? styles.selected : null}`}>
                          <Link to="/items">
                            <FontAwesomeIcon icon={faAngleRight} />
                            Items de la biblioteca
                          </Link>
                        </div>

                      )}
                    {(hasAccess(user, 'users'))
                      && (
                        <div className={`${styles.option} ${props.path.match('/usuarios') ? styles.selected : null}`}>
                          <Link to="/usuarios">
                            <FontAwesomeIcon icon={faAngleRight} />
                            Usuarios del sistema
                          </Link>
                        </div>
                      )}

                  </div>
                </div>
              </div>
            </div>
            <div className={styles.main}>
              <div className={styles.inner}>
                <Component
                  {...matchProps}
                  user={user}
                />
              </div>
            </div>
            <div className={styles.footer}>
              <div className={styles.inner}>
                <div className={styles.version}>
                  {pjson.version}
                </div>
              </div>
            </div>

          </div>
        </div>
      )}
    />
  );
};

AdminLayout.defaultProps = {
  title: '',
  back: false,
  mqtt: null,
  user: {},
  redirectTo: null,
  requiredPermissions: null,
};

AdminLayout.propTypes = {
  title: PropTypes.string,
  mqtt: PropTypes.any,
  back: PropTypes.bool,
  user: PropTypes.object,
  redirectTo: PropTypes.string,
  path: PropTypes.any.isRequired,
  requiredPermissions: PropTypes.object,
  component: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  title: state.layout && state.layout.get('title'),
  back: state.layout && state.layout.get('back'),
  user: state && state.auth && state.auth.get('user') && state.auth.get('user').toJS(),
});

/*
const mapDispatchToProps = (dispatch) => ({

          });
          */

export default connect(
  mapStateToProps,
  null, // mapDispatchToProps
)(AdminLayout);
