import apiClient from '../api';
import { executeAndWaitResponse } from './service';

export const all = async () => executeAndWaitResponse(
  () => apiClient.sessions.all(),
);
export const xlsx = async () => executeAndWaitResponse(
  () => apiClient.sessions.xlsx(),
);

export const get = async (hash) => executeAndWaitResponse(
  () => apiClient.sessions.get(hash),
);
export const update = async (hash, data) => executeAndWaitResponse(
  () => apiClient.sessions.update(hash, data),
);
export const sendAccessEmail = async (hash, email) => executeAndWaitResponse(
  () => apiClient.sessions.sendAccessEmail(hash, email),
);
export const create = async (data) => executeAndWaitResponse(
  () => apiClient.sessions.create(data),
);
export const remove = async (hash) => executeAndWaitResponse(
  () => apiClient.sessions.update(hash, { status: 'DELETED' }),
);

export default {
  all,
  xlsx,
  create,
  update,
  remove,
  get,
  sendAccessEmail,
};
