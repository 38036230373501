/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams, Link } from 'react-router-dom';
import moment from 'moment';
import {
  Row, Col,
  Form, Input,
  Button,
  Alert,
  DatePicker,
  Select,
  Popconfirm,
  notification,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faDownload,
  faInfoCircle,
  faPlus,
  faQuestion,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from 'react-csv';
import isMobile from 'is-mobile';
import { API_BASE_URL } from '../../environment';

import Loading from '../../components/Loading';

import * as customerService from '../../services/customers';

import stylesLayout from '../../common/layout.module.scss';
import stylesForm from '../../common/form.module.scss';
import EventsList from '../../components/EventsList';
import SessionsTable from '../../components/SessionsTable';

import styles from './index.module.scss';

import { sources } from '../../helpers/utils';

const signs = ['Aries', 'Tauro', 'Géminis', 'Cáncer', 'Leo', 'Virgo', 'Libra', 'Escorpio', 'Sagitario', 'Capricornio', 'Acuario', 'Piscis'].map((x) => ({ label: x, value: x }));

const CustomerModule = ({ user }) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { hash } = useParams();
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState({});

  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);

  const onSessionAction = (action, sessionHash, customerHash) => {
    switch (action) {
      case 'view':
        history.push(`/sesion/${sessionHash}/${customerHash}`);
        break;
      default:
    }
  };
  const loadCustomer = async () => {
    if (hash === 'nueva') {
      return;
    }
    const rsp = await customerService.get(hash);
    if (rsp && rsp.status) {
      setCustomer(rsp.data);
    }
  };

  const onSaveItem = async (values) => {
    setSaving(true);
    setError(null);
    const s = {
      ...customer,
      ...values,
    };
    if (hash === 'nueva') {
      const rsp = await customerService.create(s);
      setSaving(false);
      if (rsp && rsp.status) {
        history.replace(`/cliente/${rsp.data.hash}`);
      }

      return;
    }

    const rsp = await customerService.update(s.hash, s);
    if (rsp && !rsp.status) {
      setError(rsp.msg);
    }
    if (rsp && rsp.status) {
      setSaved(true);
    }
    if (rsp && !rsp.status) {
      setError(rsp.msg);
    }
    setSaving(false);
  };

  const onChangeItem = () => {
    const s = {
      ...customer,
      ...form.getFieldsValue(),
    };
    setSaved(false);
    setCustomer(s);
  };
  const onChangeField = (field, value) => {
    const s = {
      ...customer,
      [field]: value,
    };
    setSaved(false);
    setCustomer(s);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await Promise.all([
        loadCustomer(true),
      ]);
      setLoading(false);
    })();
  }, [hash]);

  const onDeleteCustomer = async () => {
    const rsp = await customerService.remove(customer.hash);
    if (rsp && !rsp.status) {
      setError(rsp.msg);
    }
    if (rsp && rsp.status) {
      notification.open({
        description:
          'Cliente eliminado',
        icon: <FontAwesomeIcon icon={faInfoCircle} color="#093" />,
      });
      window.setTimeout(() => history.replace('/clientes'), 2000);
      return;
    }
    if (rsp && !rsp.status) {
      setError(rsp.msg);
    }
    setSaving(false);
  };

  if (loading) {
    return <div className={stylesLayout.page}><Loading /></div>;
  }
  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.breadcrumb}>
        <Link to="/clientes">Clientes</Link>
        {' '}
        &raquo;
      </div>

      <div className={stylesLayout.title}>
        Cliente
      </div>
      <div className={stylesLayout.actions}>
        {user.is_admin && (
          <>
            <Popconfirm
              title="¿Confirmas eliminar este cliente?"
              onConfirm={onDeleteCustomer}
              okText="Si"
              okButtonProps={{ danger: true }}
              icon={(
                <FontAwesomeIcon
                  icon={faQuestion}
                  style={{
                    position: 'absolute', top: 8.0005, width: 15, color: '#c30',
                  }}
                />
              )}
              cancelText="No"
            >
              <Button
                style={{ whiteSpace: 'normal', height: 'auto', marginBottom: '10px' }}
                block={isMobile()}
                danger
              >
                <FontAwesomeIcon icon={faTrash} style={{ marginRight: 10 }} />
                Eliminar cliente
              </Button>
            </Popconfirm>
          </>
        )}
      </div>
      <div className={`${stylesLayout.content} ${stylesForm.form} ${styles.form}`}>
        <div className={styles.formSidebar}>
          <div>
            <div className={stylesForm.header}>
              Estadísticas
            </div>
            <div>
              <table className={[stylesLayout.table, stylesLayout.tableSemiSmall].join(' ')}>
                <tr>
                  <td>Cantidad de sesiones</td>
                  <td>{customer.n_sessions}</td>
                </tr>
                <tr>
                  <td>Primera sesión</td>
                  <td>
                    {customer.first_session_at
                      ? moment.utc(customer.first_session_at).local().format('YYYY-MM-DD HH:mm') : '-'}
                  </td>
                </tr>
                <tr>
                  <td>Última sesión</td>
                  <td>
                    {customer.last_session_at
                      ? `${moment.utc(customer.last_session_at).local().format('YYYY-MM-DD HH:mm')} (${moment.utc(customer.last_session_at).fromNow()})` : '-'}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <br />
          <EventsList events={customer?.events || []} />
        </div>
        <div className={styles.formMain}>
          {error
            && (
              <div className={stylesForm.error}>
                <Alert message={error} type="error" />
              </div>
            )}

          <Form
            name="basic"
            form={form}
            labelAlign="left"
            initialValues={customer}
            layout="horizontal"
            {...{
              labelCol: { span: 4 },
              wrapperCol: { span: 20 },
            }}
            onFinish={onSaveItem}
            onValuesChange={onChangeItem}
            size="large"
          >

            <Row>
              <Col xs={24}>
                <Form.Item
                  label="Nombre"
                  name="name"
                  rules={[{ required: true, message: 'El nombre es requerido' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Form.Item
                  label="Email"
                  name="email"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Form.Item
                  label="Teléfono"
                  name="phone"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Form.Item
                  label="Origen"
                  name="source"
                >
                  <Select
                    placeholder="Selecciona..."
                    style={{ width: '100%' }}
                    options={sources.map((x) => ({ label: x, value: x }))}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Form.Item
                  label="Fecha y hora de nacimiento"
                  {...{
                    labelCol: { span: 8 },
                    wrapperCol: { span: 16 },
                  }}
                >
                  <DatePicker
                    placeholder="Elige fecha..."
                    defaultValue={customer.birthdate && moment(customer.birthdate)}
                    onChange={(e) => { onChangeField('birthdate', e); }}
                    format="YYYY-MM-DD HH:mm"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                  />
                </Form.Item>
              </Col>
              <Col sm={12}>
                <Form.Item
                  label="Lugar de nacimiento/cumpleaños"
                  name="birthplace"
                  {...{
                    labelCol: { span: 12 },
                    wrapperCol: { span: 12 },
                  }}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={24}>
                <Form.Item
                  label="Sol"
                  name="sun"
                  {...{
                    labelCol: { span: 4 },
                    wrapperCol: { span: 20 },
                  }}
                >
                  <Select placeholder="Selecciona..." style={{ width: '100%' }} options={signs} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={24}>
                <Form.Item
                  label="Luna"
                  name="moon"
                  {...{
                    labelCol: { span: 4 },
                    wrapperCol: { span: 20 },
                  }}
                >
                  <Select placeholder="Selecciona..." style={{ width: '100%' }} options={signs} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={24}>
                <Form.Item
                  label="Ascendente"
                  name="ascendant"
                  {...{
                    labelCol: { span: 4 },
                    wrapperCol: { span: 20 },
                  }}
                >
                  <Select placeholder="Selecciona..." style={{ width: '100%' }} options={signs} />
                </Form.Item>
              </Col>
            </Row>

            <Row style={{ marginBottom: 30 }}>
              <Col offset={4} span={20} xs={{ offset: 0, span: 24 }}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" disabled={saving}>
                    Guardar
                  </Button>

                  {saving && <span className={stylesForm.saveMessage}>Guardando...</span>}
                  {!saving && saved && (
                    <span className={`${stylesForm.saveMessage} ${stylesForm.success}`}>
                      <FontAwesomeIcon icon={faCheck} />
                      {' '}
                      Guardado
                    </span>
                  )}
                </Form.Item>
              </Col>
            </Row>
            {hash !== 'nueva' && customer?.sessions && (
              <>
                <div className={stylesLayout.page}>
                  <div className={stylesLayout.title}>
                    Sesiones
                    <div className={stylesLayout.secAction}>
                      <CSVLink
                        filename="sesiones.csv"
                        data={customer?.sessions?.map((e) => ({
                          nombre: e.name,
                          email: e.email,
                          telefono: e.phone,
                          nacimiento: e.birthdate ? moment(e.birthdate).format('YYYY-MM-DD') : '',
                          sol: e.sun,
                          luna: e.moon,
                          ascendente: e.ascendant,
                        }))}
                      >
                        <Button type="secondary">
                          <FontAwesomeIcon icon={faDownload} style={{ marginRight: 5 }} />
                          Exportar como CSV

                        </Button>
                      </CSVLink>
                      {' '}
                      <Button onClick={() => {
                        window.open(`${API_BASE_URL}/sessions/xlsx?token=${localStorage.getItem('token')}`);
                      }}
                      >
                        <FontAwesomeIcon icon={faDownload} style={{ marginRight: 10 }} />
                        Exportar como Excel

                      </Button>
                      {' '}
                      <Button onClick={() => { history.push(`/sesion/nueva/${customer.hash}`); }}>
                        <FontAwesomeIcon icon={faPlus} style={{ marginRight: 10 }} />
                        Agregar
                      </Button>
                    </div>
                  </div>
                  <div className={stylesLayout.content}>
                    {customer?.sessions
                      && (
                        <SessionsTable
                          items={customer?.sessions}
                          onAction={onSessionAction}
                        />
                      )}
                  </div>
                </div>
              </>
            )}

          </Form>
        </div>
      </div>
    </div>
  );
};

CustomerModule.propTypes = {
  user: PropTypes.object.isRequired,
};

export default CustomerModule;
