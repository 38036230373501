import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Input, Table, Space,
} from 'antd';

import {
  FilterOutlined,
} from '@ant-design/icons';

import styles from './index.module.scss';
import stylesLayout from '../../common/layout.module.scss';

const
  LibrariesTable = ({
    items,
    onAction,
  }) => {
    const searchInput = useRef(null);

    const handleSearch = (confirm) => {
      confirm();
    };

    const handleReset = (clearFilters) => {
      clearFilters();
    };

    const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({
        // eslint-disable-next-line react/prop-types
        setSelectedKeys, selectedKeys, confirm, clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder="Buscar..."
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(confirm)}
              icon={<FilterOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Filtrar
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => handleReset(clearFilters)}
              style={{ width: 90 }}
            >
              Limpiar
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) => (record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : ''),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.select(), 100);
        }
      },
      render: (text) => text,
    });
    const columns = [
      {
        title: 'Nombre',
        dataIndex: 'name',
        key: 'name',
        ...getColumnSearchProps('name'),
        render: (name, r) => (
          <>
            <div
              className={`${styles.link} ${stylesLayout.link}`}
              role="button"
              tabIndex={0}
              onKeyPress={() => onAction('view', r.hash)}
              onClick={() => onAction('view', r.hash)}
            >
              {name}
            </div>
            <div className={stylesLayout.small}>{r.description}</div>
          </>
        ),
        sorter: (a, b) => (a.name < b.name ? -1 : 1),
      },
      {
        title: 'Status',
        dataIndex: 'status_tr',
        key: 'status_tr',
        align: 'center',
        ...getColumnSearchProps('status_tr'),
        render: (statusTr) => statusTr,
        sorter: (a, b) => (a.status_tr < b.status_tr ? -1 : 1),
      },
      {
        title: '',
        dataIndex: 'hash',
        key: 'hash',
        render: (hash, r) => (
          <a
            href={`https://site.almaceleste.cl/biblioteca/${hash}/${r.secret}`}
            target="_ac_session"
            className={`${styles.link} ${stylesLayout.link}`}
            role="button"
            tabIndex={0}
          >
            Ver
          </a>
        ),
      },
    ];
    return (
      <div className={styles.table}>
        <Table
          rowClassName={(r) => (r.access_mail_sent_to_user ? styles.sent : '')}
          size="small"
          hideOnSinglePage
          pagination={{ defaultPageSize: 500, hideOnSinglePage: true }}
          locale={{ emptyText: 'Sin datos' }}
          columns={columns}
          dataSource={items.map((d) => ({
            key: d.id,
            typeTr: d.type_tr,
            ...d,
          }))}
        />
      </div>
    );
  };

LibrariesTable.defaultProps = {
  onAction: () => { },

};

LibrariesTable.propTypes = {
  items: PropTypes.array.isRequired,
  onAction: PropTypes.func,
};
export default LibrariesTable;
