import React, { useRef } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  Button,
  Input, Table, Space,
} from 'antd';

import {
  FilterOutlined,
} from '@ant-design/icons';

import styles from './index.module.scss';
import stylesLayout from '../../common/layout.module.scss';

const
  GiftcardsTable = ({
    items,
    onAction,
  }) => {
    const searchInput = useRef(null);

    const handleSearch = (confirm) => {
      confirm();
    };

    const handleReset = (clearFilters) => {
      clearFilters();
    };

    const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({
        // eslint-disable-next-line react/prop-types
        setSelectedKeys, selectedKeys, confirm, clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder="Buscar..."
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(confirm)}
              icon={<FilterOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Filtrar
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => handleReset(clearFilters)}
              style={{ width: 90 }}
            >
              Limpiar
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) => (record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : ''),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.select(), 100);
        }
      },
      render: (text) => text,
    });
    const columns = [
      {
        title: 'Email enviado a destinatario',
        dataIndex: 'access_mail_sent_to_user',
        align: 'center',
        key: 'access_mail_sent_to_user',
        render: (a) => (
          a ? 'Si' : 'No'
        ),
        sorter: (a, b) => (a.name < b.name ? -1 : 1),
      },
      {
        title: 'Nombre de quien recibe',
        dataIndex: 'receiver_name',
        key: 'receiver_name',
        ...getColumnSearchProps('receiver_name'),
        render: (name, r) => (
          <div
            className={`${styles.link} ${stylesLayout.link}`}
            role="button"
            tabIndex={0}
            onKeyPress={() => onAction('view', r.hash)}
            onClick={() => onAction('view', r.hash)}
          >
            {name}
          </div>
        ),
        sorter: (a, b) => (a.receiver_email < b.receiver_email ? -1 : 1),
      },
      {
        title: 'Email de quien recibe',
        dataIndex: 'receiver_email',
        key: 'receiver_email',
        ...getColumnSearchProps('receiver_email'),
        render: (name, r) => (
          <div
            className={`${styles.link} ${stylesLayout.link}`}
            role="button"
            tabIndex={0}
            onKeyPress={() => onAction('view', r.hash)}
            onClick={() => onAction('view', r.hash)}
          >
            {name}
          </div>
        ),
        sorter: (a, b) => (a.receiver_email < b.receiver_email ? -1 : 1),
      },
      {
        title: 'Nombre de quien recibe',
        dataIndex: 'receiver_name',
        key: 'receiver_name',
        ...getColumnSearchProps('receiver_name'),
        render: (name, r) => (
          <div
            className={`${styles.link} ${stylesLayout.link}`}
            role="button"
            tabIndex={0}
            onKeyPress={() => onAction('view', r.hash)}
            onClick={() => onAction('view', r.hash)}
          >
            {name}
          </div>
        ),
        sorter: (a, b) => (a.receiver_email < b.receiver_email ? -1 : 1),
      },
      {
        title: 'Nombre de quien envía',
        dataIndex: 'sender_name',
        key: 'sender_name',
        ...getColumnSearchProps('sender_name'),
        render: (v, r) => (
          <div
            className={`${styles.link} ${stylesLayout.link}`}
            role="button"
            tabIndex={0}
            onKeyPress={() => onAction('view', r.hash)}
            onClick={() => onAction('view', r.hash)}
          >
            {v}
          </div>
        ),
        sorter: (a, b) => (a.sender_name < b.sender_name ? -1 : 1),
      }, {
        title: 'Email de quien envía',
        dataIndex: 'sender_email',
        key: 'sender_email',
        ...getColumnSearchProps('sender_email'),
        render: (v, r) => (
          <div
            className={`${styles.link} ${stylesLayout.link}`}
            role="button"
            tabIndex={0}
            onKeyPress={() => onAction('view', r.hash)}
            onClick={() => onAction('view', r.hash)}
          >
            {v}
          </div>
        ),
        sorter: (a, b) => (a.sender_email < b.sender_email ? -1 : 1),
      },
      {
        title: 'Tipo de giftcard',
        dataIndex: 'giftcard_type_tr',
        key: 'giftcard_type_tr',
        align: 'center',
        ...getColumnSearchProps('giftcard_type_tr'),
        render: (giftcardTypeTr) => giftcardTypeTr,
        sorter: (a, b) => (a.giftcard_type_tr < b.giftcard_type_tr ? -1 : 1),
      },
      {
        title: 'Tipo de servicio',
        dataIndex: 'service_type_tr',
        key: 'service_type_tr',
        align: 'center',
        ...getColumnSearchProps('service_type_tr'),
        render: (serviceTypeTr) => serviceTypeTr,
        sorter: (a, b) => (a.service_type_tr < b.service_type_tr ? -1 : 1),
      },
      {
        title: 'Fecha Envío',
        dataIndex: 'send_at',
        key: 'send_at',
        align: 'center',
        render: (date) => date && moment.utc(date).local().format('DD/MM HH:mm'),
        sorter: (a, b) => moment(b.send_at).format('X') - moment(a.send_at).format('X'),
      },
      {
        title: 'Creada',
        dataIndex: 'created_at',
        key: 'created_at',
        align: 'center',
        render: (createdAt) => moment.utc(createdAt).fromNow(),
        sorter: (a, b) => moment(b.created_at).format('X') - moment(a.created_at).format('X'),
      },
      {
        title: 'Actualizada',
        dataIndex: 'updated_at',
        key: 'updated',
        align: 'center',
        render: (updatedAt) => moment.utc(updatedAt).fromNow(),
        sorter: (a, b) => moment(b.updated_at).format('X') - moment(a.updated_at).format('X'),
      },
    ];
    return (
      <div className={styles.table}>
        <Table
          rowClassName={(r) => (r.access_mail_sent_to_user ? styles.sent : '')}
          size="small"
          hideOnSinglePage
          pagination={{ defaultPageSize: 500 }}
          locale={{ emptyText: 'Sin datos' }}
          columns={columns}
          dataSource={items.map((d) => ({
            key: d.id,
            ...d,
          }))}
        />
      </div>
    );
  };

GiftcardsTable.defaultProps = {
  onAction: () => { },

};

GiftcardsTable.propTypes = {
  items: PropTypes.array.isRequired,
  onAction: PropTypes.func,
};
export default GiftcardsTable;
