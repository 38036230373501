import apiClient from '../api';
import { executeAndWaitResponse } from './service';

export const all = async () => executeAndWaitResponse(
  () => apiClient.courses.all(),
);

export const get = async (hash) => executeAndWaitResponse(
  () => apiClient.courses.get(hash),
);
export const update = async (hash, data) => executeAndWaitResponse(
  () => apiClient.courses.update(hash, data),
);
export const create = async (data) => executeAndWaitResponse(
  () => apiClient.courses.create(data),
);
export const remove = async (hash) => executeAndWaitResponse(
  () => apiClient.courses.remove(hash),
);

export default {
  all,
  get,
  remove,
  update,
  create,
};
