import React, { useRef } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  Button,
  Input, Table, Space,
} from 'antd';

import {
  FilterOutlined,
} from '@ant-design/icons';

import styles from './index.module.scss';
import stylesLayout from '../../common/layout.module.scss';

const mapTypes = {
  '': 'Sin tipo',
  NORMAL: 'Normal',
};
const mapStatus = {
  '': 'Sin tipo',
  ACTIVE: 'Activo',
  DRAFT: 'Borrador',
  INACTIVE: 'Inactivo',
  DELETED: 'Eliminado',
};
const LessonsTable = ({
  items,
  onAction,
}) => {
  const searchInput = useRef(null);

  const handleSearch = (confirm) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      // eslint-disable-next-line react/prop-types
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder="Buscar..."
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm)}
            icon={<FilterOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Filtrar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => handleReset(clearFilters)}
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => (record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : ''),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) => text,
  });
  const columns = [

    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      render: (name, r) => (
        <div
          className={stylesLayout.link}
          role="button"
          tabIndex={0}
          onKeyPress={() => onAction('view', r.hash)}
          onClick={() => onAction('view', r.hash)}
        >
          {name}
        </div>
      ),
      sorter: (a, b) => (a.name < b.name ? -1 : 1),
    },
    {
      title: 'Tipo',
      dataIndex: 'typeTr',
      key: 'typeTr',
      align: 'center',
      ...getColumnSearchProps('typeTr'),
      render: (typeTr) => typeTr,
      sorter: (a, b) => (a.typeTr < b.typeTr ? -1 : 1),
    },
    {
      title: 'Status',
      dataIndex: 'statusTr',
      align: 'center',
      key: 'statusTr',
      ...getColumnSearchProps('statusTr'),
      render: (statusTr) => statusTr,
      sorter: (a, b) => (a.statusTr < b.statusTr ? -1 : 1),
    },
    {
      title: 'Comienzo',
      dataIndex: 'start_date',
      key: 'start_date',
      align: 'center',
      render: (startDate) => (startDate ? moment.utc(startDate).format('YYYY-MM-DD') : 'No definido'),
      sorter: (a, b) => moment(b.created_at).format('X') - moment(a.created_at).format('X'),
    },
    {
      title: 'Creado',
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'center',
      render: (createdAt) => moment.utc(createdAt).fromNow(),
      sorter: (a, b) => moment(b.created_at).format('X') - moment(a.created_at).format('X'),
    },

    {
      title: 'Actualizado',
      dataIndex: 'updated_at',
      key: 'updated',
      align: 'center',
      render: (updatedAt) => moment.utc(updatedAt).fromNow(),
      sorter: (a, b) => moment(b.updated_at).format('X') - moment(a.updated_at).format('X'),
    },
  ];
  return (
    <div className={styles.table}>
      <Table
        size="small"
        hideOnSinglePage
        pagination={{ defaultPageSize: 500 }}
        locale={{ emptyText: 'Sin clases' }}
        columns={columns}
        dataSource={items && items.map((d) => ({
          key: d.id,
          typeTr: mapTypes[d.type],
          statusTr: mapStatus[d.status],
          ...d,
        }))}
      />
    </div>
  );
};

LessonsTable.defaultProps = {
  onAction: () => { },

};

LessonsTable.propTypes = {
  items: PropTypes.array.isRequired,
  onAction: PropTypes.func,
};
export default LessonsTable;
