/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Row, Col,
  Form, Input,
  Button,
  Alert,
  Select,
  Checkbox,
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { API_BASE_URL } from '../../environment';

import Loading from '../../components/Loading';

import * as itemService from '../../services/items';

// import styles from './index.module.scss';
import stylesLayout from '../../common/layout.module.scss';
import stylesForm from '../../common/form.module.scss';
import UploadField from '../../components/UploadField';

import styles from './index.module.scss';

const { Option } = Select;

const ItemModule = (
) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { hash } = useParams();
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState({
    status: 'INACTIVE',
    available_for_sell: false,
  });

  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  /*
  const resyncItemFiles = () => {
    itemService.resyncFiles(hash);
  };
*/
  const loadItem = async (showLoader) => {
    if (hash === 'nuevo') {
      setLoading(false);
      return;
    }
    if (showLoader) {
      setLoading(true);
    }
    const rsp = await itemService.get(hash);
    if (rsp && rsp.status) {
      setItem(rsp.data);
    }
    setLoading(false);
  };

  const onSaveItem = async (values) => {
    setSaving(true);
    setError(null);
    const s = {
      ...item,
      ...values,
    };
    if (!s.available_for_sell) {
      s.sell_price = 0;
    }

    if (hash === 'nuevo') {
      const rsp = await itemService.create(s);
      setSaving(false);
      if (rsp && rsp.status) {
        history.replace(`/item/${rsp.data.hash}`);
      }

      return;
    }

    const rsp = await itemService.update(s.hash, s);
    if (rsp && !rsp.status) {
      setError(rsp.msg);
    }
    if (rsp && rsp.status) {
      setSaved(true);
    }
    if (rsp && !rsp.status) {
      setError(rsp.msg);
    }
    setSaving(false);
  };

  const onChangeItem = () => {
    const s = {
      ...item,
      ...form.getFieldsValue(),
    };
    setSaved(false);
    setItem(s);
  };
  // eslint-disable-next-line no-unused-vars
  const onChangeField = (field, value) => {
    const s = {
      ...item,
      [field]: value,
    };
    setSaved(false);
    setItem(s);
  };

  useEffect(() => {
    loadItem(true);
  }, [hash]);

  if (loading) {
    return <div className={stylesLayout.page}><Loading /></div>;
  }
  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.breadcrumb}>
        <a onClick={() => { history.push('/items'); }}>Items</a>
        {' '}
        &raquo;
      </div>
      <div className={stylesLayout.title}>
        {hash === 'nuevo' ? 'Nuevo Item' : item.title}
      </div>
      <div className={stylesLayout.meta} />

      <div className={`${stylesLayout.content} ${stylesForm.form}`}>
        {error
          && (
            <div className={stylesForm.error}>
              <Alert message={error} type="error" />
            </div>
          )}

        <Form
          name="basic"
          form={form}
          labelAlign="left"
          initialValues={item}
          layout="horizontal"
          {...{
            labelCol: { span: 3 },
            wrapperCol: { span: 21 },
          }}
          onFinish={onSaveItem}
          onValuesChange={onChangeItem}
          size="large"
        >

          <Row>
            <Col xs={24}>
              <Form.Item
                label="Título"
                name="title"
                rules={[{ required: true, message: 'El título es requerido' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form.Item
                label="Descripción"
                name="description"
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form.Item
                label="Status"
                name="status"
              >
                <Select placeholder="Selecciona..." style={{ width: '100%' }}>
                  <Option value="ACTIVE">Activo</Option>
                  <Option value="INACTIVE">Inactivo</Option>
                  <Option value="DELETED">Eliminado</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form.Item
                label="Disponible para vender"
                name="available_for_sell"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col>
          </Row>
          {
            item.available_for_sell
            && (
              <Row>
                <Col xs={24}>
                  <Form.Item
                    label="Precio de venta"
                    name="sell_price"
                    initialValue={0}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            )
          }
          {
            item.hash
            && (
              <>
                <Row>
                  <Col xs={24}>
                    <Form.Item
                      label="Portada"
                      name=""
                    >
                      <div className={styles.uploadField}>
                        {item.data?.content?.portada?.content?.webContentLink
                          && (
                            <img
                              className={styles.img}
                              src={`https://th.qoopit.io/unsafe/fit-in/300x300/filters:fill(white,true)/${encodeURIComponent(item.data?.content?.portada?.content?.webContentLink)}`}
                              alt=""
                            />
                          )}
                        <div className={styles.upload}>
                          <UploadField
                            uploadData={{ type: 'portada' }}
                            uploadUrl={`${API_BASE_URL}/item/${item.hash}/upload`}
                            onUploadComplete={loadItem}
                          />
                        </div>

                      </div>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <Form.Item
                      label="Archivo PDF para descarga"
                      name=""
                    >
                      <div className={styles.uploadField}>
                        {item.data?.content?.downloadable?.content?.webContentLink
                          && (
                            <a className={styles.objDownload} href={item.data?.content?.downloadable?.content?.webContentLink} target="_blank" rel="noreferrer">
                              <FontAwesomeIcon icon={faFilePdf} />
                              {' '}
                              Ver archivo

                            </a>
                          )}
                        <div className={styles.upload}>
                          <UploadField
                            accept="application/pdf"
                            uploadData={{ type: 'downloadable' }}
                            uploadUrl={`${API_BASE_URL}/item/${item.hash}/upload`}
                            onUploadComplete={loadItem}
                          />
                        </div>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )
          }
          <Row style={{ marginBottom: 30 }}>
            <Col offset={4} span={20} xs={{ offset: 0, span: 24 }}>
              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={saving}>
                  Guardar
                </Button>

                {saving && <span className={stylesForm.saveMessage}>Guardando...</span>}
                {!saving && saved && (
                  <span className={`${stylesForm.saveMessage} ${stylesForm.success}`}>
                    <FontAwesomeIcon icon={faCheck} />
                    {' '}
                    Guardado
                  </span>
                )}
              </Form.Item>
            </Col>
          </Row>

        </Form>
      </div>
    </div>
  );
};

ItemModule.propTypes = {
};

export default ItemModule;
