import apiClient from '../api';
import { executeAndWaitResponse } from './service';

export const remove = async (id) => executeAndWaitResponse(
  () => apiClient.files.remove(id),
);
export const changeTitle = async (id, title) => executeAndWaitResponse(
  () => apiClient.files.changeTitle(id, title),
);

export default {
  remove,
  changeTitle,
};
