import {
  fromJS,
} from 'immutable';

import {
  SET_TITLE,
  SET_BACK,
} from './constants';

const defaultState = fromJS({
  title: 'Home',
});

const defaultLayoutReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_TITLE: {
      return state.set('title', action.payload.title);
    }
    case SET_BACK: {
      return state.set('back', action.payload.back);
    }
    default: {
      return state;
    }
  }
};

export default defaultLayoutReducer;
