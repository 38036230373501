import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';

import 'antd/dist/reset.css';

import moment from 'moment';
import { ConfigProvider } from 'antd';
import Routes from './routes';

import '@react-page/editor/lib/index.css';
import configureStore from './store';
import * as serviceWorker from './serviceWorker';
import './index.css';

import 'moment/locale/es';

moment.locale('es');

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://664ac28657194ca29de7db1789360c00@o562983.ingest.sentry.io/5702333',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const store = configureStore({}, createBrowserHistory());
ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#54D1EF',
        },
      }}
    >
      <Routes />
    </ConfigProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
