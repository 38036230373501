import apiClient from '../api';
import { executeAndWaitResponse } from './service';

export const register = async (payload) => executeAndWaitResponse(
  () => apiClient.users.register(payload),
);

export const login = async (payload) => executeAndWaitResponse(
  () => apiClient.users.login(payload),
);

export const find = async (payload) => executeAndWaitResponse(
  () => apiClient.users.find(payload),
);

export const get = async (id) => executeAndWaitResponse(
  () => apiClient.users.get(id),
);
export const forgetPassword = async (payload) => executeAndWaitResponse(
  () => apiClient.users.forgetPassword(payload),
);

export const modifyPassword = async (payload) => executeAndWaitResponse(
  () => apiClient.users.modifyPassword(payload),
);

export const adminChangePassword = async (id, password) => executeAndWaitResponse(
  () => apiClient.users.adminChangePassword(id, password),
);

export const updateField = async (id, field, value) => executeAndWaitResponse(
  () => apiClient.users.updateField(id, field, value),
);

export const getUsersByRole = async (role) => executeAndWaitResponse(
  () => apiClient.users.getUsersByRole(role),
);

export default {
  get,
  login,
  find,
  register,
  getUsersByRole,
  updateField,
  forgetPassword,
  adminChangePassword,
  modifyPassword,
};
