/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams, Link } from 'react-router-dom';
import isMobile from 'is-mobile';
import moment from 'moment';
import {
  Row, Col,
  Form, Input,
  Button,
  Alert,
  DatePicker,
  Popconfirm,
  Select,
  notification,
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck, faInfoCircle, faQuestion, faTrash,
} from '@fortawesome/free-solid-svg-icons';

import Loading from '../../components/Loading';

import * as giftcardService from '../../services/giftcards';

import stylesLayout from '../../common/layout.module.scss';
import stylesForm from '../../common/form.module.scss';
import EventsList from '../../components/EventsList';

import styles from './index.module.scss';

const { Option } = Select;

const GiftcardModule = ({
  user,
}) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { hash } = useParams();
  const [loading, setLoading] = useState(true);
  const [giftcard, setGiftcard] = useState([]);

  const [saving, setSaving] = useState(false);
  const [sending, setSending] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);

  const loadGiftcard = async () => {
    if (hash === 'nueva') {
      return;
    }
    const rsp = await giftcardService.get(hash);
    if (rsp && rsp.status) {
      setGiftcard(rsp.data);
    }
  };

  const onSaveItem = async (values) => {
    setSaving(true);
    setError(null);
    const s = {
      ...giftcard,
      ...values,
    };
    if (hash === 'nueva') {
      const rsp = await giftcardService.create(s);
      setSaving(false);
      if (rsp && rsp.status) {
        history.replace('/giftcards');
      }

      return;
    }

    const rsp = await giftcardService.update(s.hash, s);
    if (rsp && !rsp.status) {
      setError(rsp.msg);
    }
    if (rsp && rsp.status) {
      setSaved(true);
    }
    if (rsp && !rsp.status) {
      setError(rsp.msg);
    }
    setSaving(false);
  };

  const onDeleteGiftcard = async () => {
    const rsp = await giftcardService.remove(giftcard.hash);
    if (rsp && !rsp.status) {
      setError(rsp.msg);
    }
    if (rsp && rsp.status) {
      notification.open({
        description:
          'Giftcard eliminada',
        icon: <FontAwesomeIcon icon={faInfoCircle} color="#093" />,
      });
      window.setTimeout(() => history.replace('/giftcards'), 2000);
      return;
    }
    if (rsp && !rsp.status) {
      setError(rsp.msg);
    }
    setSaving(false);
  };

  const onChangeItem = () => {
    const s = {
      ...giftcard,
      ...form.getFieldsValue(),
    };
    setSaved(false);
    setGiftcard(s);
  };
  const onChangeField = (field, value) => {
    const s = {
      ...giftcard,
      [field]: value,
    };
    setSaved(false);
    setGiftcard(s);
  };

  const sendEmail = async (email) => {
    setSending(true);
    const rsp = await giftcardService.sendReceiverEmail(giftcard.hash, email);
    setSending(false);
    if (rsp && rsp.status) {
      setGiftcard(rsp.data);
      // eslint-disable-next-line no-alert
      notification.open({
        description:
          `Email de acceso enviado a ${email}`,
        icon: <FontAwesomeIcon icon={faInfoCircle} color="#093" />,
      });
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await Promise.all([
        loadGiftcard(true),
      ]);
      setLoading(false);
    })();
  }, [hash]);

  if (loading) {
    return <div className={stylesLayout.page}><Loading /></div>;
  }
  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.breadcrumb}>
        <Link to="/giftcards">Sesiones</Link>
        {' '}
        &raquo;
      </div>

      <div className={stylesLayout.title}>
        Giftcard
      </div>
      <div className={stylesLayout.actions}>
        {user.is_admin && false && (
          <>
            <Button
              disabled={sending}
              onClick={() => { sendEmail(user.email); }}
              style={{ whiteSpace: 'normal', height: 'auto', marginBottom: '10px' }}
              block={isMobile()}
            >
              Enviar email de acceso a mi email (
              {user.email}
              )
            </Button>
            {' '}
            <Button
              disabled={sending || !giftcard.email}
              onClick={() => { sendEmail(giftcard.email); }}
              style={{ whiteSpace: 'normal', height: 'auto', marginBottom: '10px' }}
              block={isMobile()}
            >
              Enviar email de acceso a
              {' '}
              {giftcard.name}
            </Button>
            {' '}
            <Popconfirm
              title="¿Confirmas eliminar esta giftcard?"
              onConfirm={onDeleteGiftcard}
              okText="Si"
              okButtonProps={{ danger: true }}
              icon={(
                <FontAwesomeIcon
                  icon={faQuestion}
                  style={{
                    position: 'absolute', top: 8.0005, width: 15, color: '#c30',
                  }}
                />
              )}
              cancelText="No"
            >
              <Button
                style={{ whiteSpace: 'normal', height: 'auto', marginBottom: '10px' }}
                block={isMobile()}
                danger
              >
                <FontAwesomeIcon icon={faTrash} style={{ marginRight: 10 }} />
                Eliminar giftcard
              </Button>
            </Popconfirm>
          </>
        )}
      </div>
      <div className={`${stylesLayout.content} ${stylesForm.form} ${styles.form}`}>
        <div className={styles.formSidebar}><EventsList events={giftcard?.events || []} /></div>
        <div className={styles.formMain}>
          {error
            && (
              <div className={stylesForm.error}>
                <Alert message={error} type="error" />
              </div>
            )}

          <Form
            name="basic"
            form={form}
            labelAlign="left"
            initialValues={giftcard}
            layout="horizontal"
            {...{
              labelCol: { span: 6 },
              wrapperCol: { span: 18 },
            }}
            onFinish={onSaveItem}
            onValuesChange={onChangeItem}
            size="large"
          >

            <Row>
              <Col xs={24}>
                <Form.Item
                  label="Nombre de quien recibe"
                  name="receiver_name"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Form.Item
                  label="Email de quien recibe"
                  name="receiver_email"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Form.Item
                  label="Nombre de quien envía"
                  name="sender_name"
                  rules={[{ required: true, message: 'El nombre de quien envía es requerido' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Form.Item
                  label="Email de quien envía"
                  name="sender_email"
                  rules={[{ required: true, message: 'El email de quien envía es requerido' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col sm={24}>
                <Form.Item
                  label="Tipo de servicio"
                  name="service_type"

                >
                  <Select placeholder="Selecciona..." style={{ width: '100%' }}>
                    <Option value="carta_natal">Carta Natal</Option>
                    <Option value="revolucion_solar">Revolución Solar</Option>
                    <Option value="transitos_planetarios">Tránsitos Planetarios</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col sm={24}>
                <Form.Item
                  label="Tipo de giftcard"
                  name="giftcard_type"

                >
                  <Select placeholder="Selecciona..." style={{ width: '100%' }}>
                    <Option value="birthday">Cumpleaños</Option>
                    <Option value="christmas">Navidad</Option>
                    <Option value="other">Otro</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col sm={24}>
                <Form.Item
                  label="Fecha y hora de envío"
                >
                  <DatePicker
                    placeholder="Elige fecha..."
                    defaultValue={giftcard.send_at && moment(giftcard.send_at)}
                    onChange={(e) => { onChangeField('send_at', e); }}
                    format="YYYY-MM-DD HH:mm"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row style={{ marginBottom: 30 }}>
              <Col offset={4} span={20} xs={{ offset: 0, span: 24 }}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" disabled={saving}>
                    Guardar
                  </Button>

                  {saving && <span className={stylesForm.saveMessage}>Guardando...</span>}
                  {!saving && saved && (
                    <span className={`${stylesForm.saveMessage} ${stylesForm.success}`}>
                      <FontAwesomeIcon icon={faCheck} />
                      {' '}
                      Guardado
                    </span>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

GiftcardModule.propTypes = {
  user: PropTypes.object.isRequired,
};

export default GiftcardModule;
