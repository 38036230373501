export default (client, defaultParams) => ({
  getTenant: (payload) => (
    client('/user/tenant', {
      method: 'GET',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
  getUsersByRole: (role) => (
    client(`/users/list/by_role/${role}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  register: (payload) => (
    client('/register', {
      method: 'POST',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
  login: (payload) => (
    client('/login', {
      method: 'POST',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
  find: (payload) => (
    client('/admin/users/find', {
      method: 'POST',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
  updateField: (id, field, value) => (
    client(`/admin/user/${id}`, {
      method: 'PUT',
      data: {
        [field]: value,
      },
      params: {
        ...defaultParams,
      },
    })
  ),
  get: (id) => (
    client(`/admin/user/${id}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  forgetPassword: (payload) => (
    client('/user/forget_password', {
      method: 'POST',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
  changePassword: (payload) => (
    client('/user/change_password', {
      method: 'PUT',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
  adminChangePassword: (id, password) => (
    client(`/admin/user/${id}/modify_password`, {
      method: 'PUT',
      data: {
        password,
      },
      params: {
        ...defaultParams,
      },
    })
  ),

  getMessage: (k) => {
    const messages = {
      no_login: 'Email o contraseña incorrecta',
    };
    return (messages[k] || k);
  },
});
