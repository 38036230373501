/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faUpload } from '@fortawesome/free-solid-svg-icons';
import UploaderFile from '../UploaderFile';
import { humanFileSize } from '../../helpers/utils';

import styles from './index.module.scss';

const UploadField = ({
  uploadUrl,
  uploadData,
  onUploadComplete,
}) => (
  <>
    <div>
      <div className={styles.uploader}>
        <UploaderFile
          className={styles.uploader}
          uploadData={uploadData}
          maxSizeMB={2048}
          accept={false}
          uploadUrl={uploadUrl}
          render={({
            uploading, progress,
          }) => (
            <>
              {!uploading && (
                <div className={styles.uploaderInner}>
                  <FontAwesomeIcon icon={faUpload} style={{ marginRight: 10 }} />
                  {' '}
                  Haz click o arrastra un nuevo archivo aquí
                </div>
              )}
              {uploading && progress && progress.map((p) => (
                <div className={styles.fileDetail}>
                  <div className={styles.fileDetailProgress} style={{ width: `${p.percent}%` }} />
                  <div className={styles.fileDetailInner}>
                    {p.percent >= 100
                      ? <FontAwesomeIcon icon={faCheck} color="#093" style={{ marginRight: 10, color: '#093' }} />
                      : <FontAwesomeIcon icon={faUpload} color="#999" style={{ marginRight: 10, color: '#999' }} />}
                    <div className={styles.fileDetailName}>
                      <span style={{
                        display: 'inline-block', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '80%',
                      }}
                      >
                        {p.name}

                      </span>
                      {p.percent >= 100 && <span> - Guardando...</span>}
                    </div>
                    <div className={styles.fileDetailSize}>
                      {humanFileSize((p.size * p.percent) / 100)}
                      {' '}
                      de
                      {' '}
                      {humanFileSize(p.size)}
                    </div>
                    <div className={styles.fileDetailPercent}>
                      {parseInt(p.percent, 10)}
                      %
                    </div>

                  </div>

                </div>
              ))}
            </>
          )}
          onUpload={(data) => {
            onUploadComplete(data);
          }}
        />
      </div>
    </div>
  </>
);
UploadField.defaultProps = {
  uploadData: {},
  onUploadComplete: () => { },
};

UploadField.propTypes = {
  uploadUrl: PropTypes.string.isRequired,
  uploadData: PropTypes.any,
  onUploadComplete: PropTypes.func,
};

export default UploadField;
