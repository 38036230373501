import apiClient from '../api';
import { executeAndWaitResponse } from './service';

export const all = async () => executeAndWaitResponse(
  () => apiClient.customers.all(),
);
export const xlsx = async () => executeAndWaitResponse(
  () => apiClient.customers.xlsx(),
);

export const get = async (hash) => executeAndWaitResponse(
  () => apiClient.customers.get(hash),
);
export const update = async (hash, data) => executeAndWaitResponse(
  () => apiClient.customers.update(hash, data),
);
export const create = async (data) => executeAndWaitResponse(
  () => apiClient.customers.create(data),
);
export const remove = async (hash) => executeAndWaitResponse(
  () => apiClient.customers.update(hash, { status: 'DELETED' }),
);

export default {
  all,
  xlsx,
  create,
  update,
  remove,
  get,
};
