export const sources = ['Instagram', 'Publicidad', 'Recomendacion', 'Otro'];

export const humanFileSize = (bytes, si = false, dp = 1) => {
  let fbytes = bytes;
  const thresh = si ? 1000 : 1024;

  if (Math.abs(fbytes) < thresh) {
    return `${fbytes} B`;
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    fbytes /= thresh;
    u += 1;
  } while (Math.round(Math.abs(fbytes) * r) / r >= thresh && u < units.length - 1);

  return `${fbytes.toFixed(dp)} ${units[u]}`;
};

export const slugify = (str) => {
  if (!str) {
    return '';
  }
  let newStr = str.replace(/^\s+|\s+$/g, ''); // trim
  newStr = newStr.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaeeeeiiiioooouuuunc------';
  for (let i = 0, l = from.length; i < l; i += 1) {
    newStr = newStr.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  newStr = newStr.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return newStr;
};

export const hasRole = (user, role) => {
  if (!user) {
    return false;
  }
  if (user.is_admin) {
    return true;
  }
  if (user.roles && user.roles.map((x) => x.role).includes(role)) {
    return true;
  }
  return false;
};

export const isDev = process.env.NODE_ENV === 'development';
export const isProduction = process.env.NODE_ENV === 'production';

export const hasAccess = (user, area) => {
  switch (area) {
    case 'customers':
      return hasRole(user, 'customers');
    case 'users':
      return hasRole(user, 'users');
    case 'sessions':
      return hasRole(user, 'sessions');
    case 'courses':
      return hasRole(user, 'courses');
    case 'giftcards':
      return hasRole(user, 'giftcards');
    case 'libraries':
      return hasRole(user, 'libraries');
    default:
      return false;
  }
};

export default {
  hasRole,
  hasAccess,
  slugify,
  sources,
  isDev,
  isProduction,
  humanFileSize,
};
