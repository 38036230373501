/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import stylesForm from '../../common/form.module.scss';
import stylesLayout from '../../common/layout.module.scss';

const EventsList = ({
  events,
}) => (

  <div className={stylesLayout.events}>
    <div className={stylesForm.header}>
      Historial
    </div>
    {(!events || events.length === 0)
      ? <div>No hay eventos</div>
      : events.map((e) => (
        <div className={stylesLayout.event}>
          <div className={stylesLayout.date}>
            {moment.utc(e.created_at).local().format('YYYY-MM-DD HH:mm:ss')}
          </div>
          <div className={stylesLayout.text}>
            {e.data?.text}
          </div>
        </div>
      ))}
  </div>
);
EventsList.defaultProps = {
};

EventsList.propTypes = {
  events: PropTypes.array.isRequired,
};

export default EventsList;
