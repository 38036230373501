import React, { useRef } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  Button,
  Input, Table, Space,
} from 'antd';

import {
  FilterOutlined,
} from '@ant-design/icons';

import styles from './index.module.scss';
import stylesLayout from '../../common/layout.module.scss';

const
  CustomersTable = ({
    items,
    onAction,
  }) => {
    const searchInput = useRef(null);

    const handleSearch = (confirm) => {
      confirm();
    };

    const handleReset = (clearFilters) => {
      clearFilters();
    };

    const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({
        // eslint-disable-next-line react/prop-types
        setSelectedKeys, selectedKeys, confirm, clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder="Buscar..."
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(confirm)}
              icon={<FilterOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Filtrar
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => handleReset(clearFilters)}
              style={{ width: 90 }}
            >
              Limpiar
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) => (record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : ''),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.select(), 100);
        }
      },
      render: (text) => text,
    });
    const columns = [
      {
        title: 'Nombre',
        dataIndex: 'name',
        key: 'name',
        ...getColumnSearchProps('name'),
        render: (name, r) => (
          <div
            className={`${styles.link} ${stylesLayout.link}`}
            role="button"
            tabIndex={0}
            onKeyPress={() => onAction('view', r.hash)}
            onClick={() => onAction('view', r.hash)}
          >
            {name}
          </div>
        ),
        sorter: (a, b) => (a.name < b.name ? -1 : 1),
      },
      {
        title: 'Sol',
        dataIndex: 'sun',
        key: 'sun',
        align: 'center',
        ...getColumnSearchProps('sun'),
        render: (sun) => sun,
        sorter: (a, b) => (a.sun < b.sun ? -1 : 1),
      },
      {
        title: 'Luna',
        dataIndex: 'moon',
        key: 'moon',
        align: 'center',
        ...getColumnSearchProps('moon'),
        render: (moon) => moon,
        sorter: (a, b) => (a.moon < b.moon ? -1 : 1),
      },
      {
        title: 'Ascendente',
        dataIndex: 'ascendant',
        key: 'ascendant',
        align: 'center',
        ...getColumnSearchProps('ascendant'),
        render: (ascendant) => ascendant,
        sorter: (a, b) => (a.ascendant < b.ascendant ? -1 : 1),
      },
      {
        title: 'Cantidad de sesiones',
        dataIndex: 'n_sessions',
        key: 'n_sessions',
        align: 'center',
        render: (x) => x || 0,
        sorter: (a, b) => (a.n_sessions < b.n_sessions ? -1 : 1),
      },
      {
        title: 'Primera Sesión',
        dataIndex: 'first_session_at',
        key: 'first_session_at',
        align: 'center',
        render: (x) => (x ? moment.utc(x).local().format('YYYY-MM-DD') : ''),
        sorter: (a, b) => moment(b.first_session_at).format('X') - moment(a.first_session_at).format('X'),
      },
      {
        title: 'Última Sesión',
        dataIndex: 'last_session_at',
        key: 'last_session_at',
        align: 'center',
        render: (x) => (x ? moment.utc(x).local().format('YYYY-MM-DD') : ''),
        sorter: (a, b) => moment(b.last_session_at).format('X') - moment(a.last_session_at).format('X'),
      },
      {
        title: 'Creado',
        dataIndex: 'created_at',
        key: 'created_at',
        align: 'center',
        render: (createdAt) => moment.utc(createdAt).fromNow(),
        sorter: (a, b) => moment(b.created_at).format('X') - moment(a.created_at).format('X'),
      },
      {
        title: 'Actualizado',
        dataIndex: 'updated_at',
        key: 'updated',
        align: 'center',
        render: (updatedAt) => moment.utc(updatedAt).fromNow(),
        sorter: (a, b) => moment(b.updated_at).format('X') - moment(a.updated_at).format('X'),
      },
    ];
    return (
      <div className={styles.table}>
        <Table
          rowClassName={(r) => (r.access_mail_sent_to_user ? styles.sent : '')}
          size="small"
          hideOnSinglePage
          pagination={{ defaultPageSize: 500 }}
          locale={{ emptyText: 'Sin datos' }}
          columns={columns}
          dataSource={items.map((d) => ({
            key: d.id,
            typeTr: d.type_tr,
            ...d,
          }))}
        />
      </div>
    );
  };

CustomersTable.defaultProps = {
  onAction: () => { },

};

CustomersTable.propTypes = {
  items: PropTypes.array.isRequired,
  onAction: PropTypes.func,
};
export default CustomersTable;
