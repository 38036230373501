/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

import {
  Button,
  Input, Table,
} from 'antd';
// import { useSearchableTable } from '../../hooks/useSearchableTable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

import * as usersService from '../../services/users';

import styles from './index.module.scss';
import Loading from '../../components/Loading';
import stylesLayout from '../../common/layout.module.scss';

const UsersModule = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [q, setQ] = useState(null);
  const [results, setResults] = useState(null);

  const onStartSearch = async (forceQ) => {
    const searchQ = (forceQ || q).replaceAll('\n', ',').replaceAll(' ', ',');
    if (!searchQ) {
      return;
    }
    setLoading(true);
    const rspUsers = await usersService.find({
      q: searchQ,
    });

    if (rspUsers && rspUsers.status) {
      setResults(rspUsers.data);
    }

    setLoading(false);
  };

  const usersColumns = [
    {
      title: 'Id',
      key: 'id',
      dataIndex: 'id',
      render: (id) => (
        <>
          <div>
            <Link to={`/usuario/${id}`}>{id}</Link>

          </div>
        </>
      ),
    },

    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      render: (name) => name,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (email) => email,
    },
    {
      title: 'Teléfono',
      dataIndex: 'phone',
      key: 'phone',
      render: (phone) => phone,
    },
  ];

  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>
        <div className={stylesLayout.secAction} style={{ marginTop: -5 }}>
          <Button onClick={() => history.push('/usuario/crear')} size="medium">
            <FontAwesomeIcon icon={faPlus} style={{ marginRight: 5 }} />
            {' '}
            Crear usuario
          </Button>
        </div>
        Usuarios
      </div>
      <div className={stylesLayout.content}>
        <div className={styles.input}>
          <Input value={q} placeholder="Email o nombre del usuario..." onChange={(e) => setQ(e.target.value)} onKeyDown={(e) => (e.key === 'Enter' ? onStartSearch() : null)} />

          <Button type="primary" onClick={() => onStartSearch()} disabled={!q || loading}>{loading ? 'Cargando' : 'Buscar'}</Button>
        </div>

        {loading
          ? <Loading />
          : (results?.length > 0
            ? (
              <div className={stylesLayout.box}>
                <div className={styles.table}>
                  <Table
                    loading={loading}
                    size="small"
                    pagination={{ hideOnSinglePage: true, defaultPageSize: 25 }}
                    locale={{ emptyText: 'Sin datos' }}
                    columns={usersColumns}
                    dataSource={results.map((d) => ({
                      key: d.id,
                      ...d,
                    }))}
                  />
                </div>

              </div>
            ) : (
              <div className={stylesLayout.message}>
                No hay resultados
              </div>
            ))}

      </div>
    </div>
  );
};

UsersModule.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  user: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  tenant: PropTypes.object.isRequired,
};
export default UsersModule;
