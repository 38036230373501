/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import moment from 'moment';
import {
  Row, Col,
  Form, Input,
  Button,
  Alert,
  DatePicker,
  Select,
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';

import { slugify } from '../../helpers/utils';

import Loading from '../../components/Loading';
import CopyWithMessage from '../../components/CopyWithMessage';
import LessonsTable from '../../components/LessonsTable';

import * as courseService from '../../services/courses';

// import styles from './index.module.scss';
import stylesLayout from '../../common/layout.module.scss';
import stylesForm from '../../common/form.module.scss';

const { Option } = Select;

const CourseModule = (
) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { hash } = useParams();
  const [loading, setLoading] = useState(true);
  const [course, setCourse] = useState([]);

  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);

  const loadCourse = async (showLoader) => {
    if (hash === 'nuevo') {
      setLoading(false);
      return;
    }
    if (showLoader) {
      setLoading(true);
    }
    const rsp = await courseService.get(hash);
    if (rsp && rsp.status) {
      setCourse(rsp.data);
    }
    setLoading(false);
  };

  const onSaveItem = async (values) => {
    setSaving(true);
    setError(null);
    const s = {
      ...course,
      ...values,
    };
    if (hash === 'nuevo') {
      const rsp = await courseService.create(s);
      setSaving(false);
      if (rsp && rsp.status) {
        history.replace(`/curso/${rsp.data.hash}`);
      }

      return;
    }

    const rsp = await courseService.update(s.hash, s);
    if (rsp && !rsp.status) {
      setError(rsp.msg);
    }
    if (rsp && rsp.status) {
      setSaved(true);
    }
    if (rsp && !rsp.status) {
      setError(rsp.msg);
    }
    setSaving(false);
  };

  const onChangeItem = () => {
    const s = {
      ...course,
      ...form.getFieldsValue(),
    };
    setSaved(false);
    setCourse(s);
  };
  const onChangeField = (field, value) => {
    const s = {
      ...course,
      [field]: value,
    };
    setSaved(false);
    setCourse(s);
  };

  const onLessonAction = (action, lessonHash) => {
    switch (action) {
      case 'view':
        history.push(`/clase/${lessonHash}`);
        break;
      default:
    }
  };

  useEffect(() => {
    loadCourse(true);
  }, [hash]);

  if (loading) {
    return <div className={stylesLayout.page}><Loading /></div>;
  }
  const siteFrontUrl = course && `https://site.almaceleste.cl/curso/${slugify(course.name)}/${hash}`;
  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.breadcrumb}>
        <Link to="/cursos">Cursos</Link>
        {' '}
        &raquo;
      </div>
      <div className={stylesLayout.title}>
        {hash === 'nuevo' ? 'Nuevo curso' : course.name}
      </div>
      <div className={stylesLayout.meta}>
        {hash !== 'nuevo' && (
          <div style={{
            verticalAlign: 'top',
            marginTop: -20,
            marginBottom: 40,
            display: 'grid',
            gridTemplateColumns: 'auto 1fr',
            gap: 10,
            maxWidth: '70%',
            lineHeight: '150%',
            width: 'auto',
          }}
          >
            <div
              style={{
                display: 'inline-block', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '100%',
              }}
            >
              <a
                href={siteFrontUrl}
                target="_ac_course"
                className={stylesLayout.link}
                role="button"
                tabIndex={0}
                style={{
                  textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: 300,
                }}
              >
                {siteFrontUrl}
              </a>
            </div>
            <div>
              <CopyWithMessage value={siteFrontUrl} />
            </div>
          </div>
        )}
      </div>

      <div className={`${stylesLayout.content} ${stylesForm.form}`}>
        {error
          && (
            <div className={stylesForm.error}>
              <Alert message={error} type="error" />
            </div>
          )}

        <Form
          name="basic"
          form={form}
          labelAlign="left"
          initialValues={course}
          layout="horizontal"
          {...{
            labelCol: { span: 3 },
            wrapperCol: { span: 21 },
          }}
          onFinish={onSaveItem}
          onValuesChange={onChangeItem}
          size="large"
        >

          <Row>
            <Col xs={24}>
              <Form.Item
                label="Nombre"
                name="name"
                rules={[{ required: true, message: 'El nombre es requerido' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form.Item
                label="Tipo"
                name="type"
              >
                <Select placeholder="Selecciona..." style={{ width: '100%' }}>
                  <Option value="NORMAL">Normal</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form.Item
                label="Status"
                name="status"
              >
                <Select placeholder="Selecciona..." style={{ width: '100%' }}>
                  <Option value="DRAFT">Borrador</Option>
                  <Option value="ACTIVE">Activo</Option>
                  <Option value="INACTIVE">Inactivo</Option>
                  <Option value="DELETED">Eliminado</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form.Item
                label="Fecha inicio"
              >
                <DatePicker
                  placeholder="Elige fecha..."
                  defaultValue={course.start_date && moment(course.start_date)}
                  onChange={(e) => { onChangeField('start_date', e); }}
                  format="YYYY-MM-DD"
                  style={{ width: '100%', maxWidth: 300 }}
                />
              </Form.Item>

            </Col>
          </Row>
          <Row style={{ marginBottom: 30 }}>
            <Col offset={4} span={20} xs={{ offset: 0, span: 24 }}>
              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={saving}>
                  Guardar
                </Button>

                {saving && <span className={stylesForm.saveMessage}>Guardando...</span>}
                {!saving && saved && (
                  <span className={`${stylesForm.saveMessage} ${stylesForm.success}`}>
                    <FontAwesomeIcon icon={faCheck} />
                    {' '}
                    Guardado
                  </span>
                )}
              </Form.Item>
            </Col>
          </Row>
          {hash !== 'nuevo' && (
            <>
              <div className={stylesForm.header}>
                Clases
                <div className={stylesLayout.secAction}>
                  <Button onClick={() => { history.push(`/clase/nueva/${course.id}`); }}>
                    <FontAwesomeIcon icon={faPlus} style={{ marginRight: 10 }} />
                    Agregar
                  </Button>
                </div>
              </div>
              <LessonsTable
                items={course.lessons}
                onAction={onLessonAction}
              />
            </>
          )}
        </Form>
      </div>
    </div>
  );
};

CourseModule.propTypes = {
};

export default CourseModule;
