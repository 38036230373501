/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { humanFileSize } from '../../helpers/utils';

import styles from './index.module.scss';

const FileRow = ({ file, onDelete, onSaveChangeTitle }) => {
  const [editing, setEditing] = useState(false);
  const [title, setTitle] = useState(file.title);
  useEffect(() => {
    setTitle(file.title);
  }, [file]);
  return (
    <tr>
      <td className={styles.fileInfo}>
        <img src={file.iconLink.replace('/16/', '/32/')} alt="" />
        <div>
          {editing
            ? (
              <>
                <Input
                  defaultValue={title}
                  onChange={(e) => setTitle(e.target.value)}
                  addonAfter={(
                    <Button
                      type="secondary"
                      size="small"
                      onClick={() => {
                        onSaveChangeTitle(title);
                        setEditing(false);
                      }}
                    >
                      Cambiar

                    </Button>
                  )}
                />

                <div className={styles.changeName} onClick={() => setEditing(false)}>Cancelar</div>
              </>
            )
            : (
              <>
                <a
                  href={file.webContentLink}
                  target="_gd_file"
                  style={{
                    display: 'inline-block', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '90%',
                  }}
                >
                  {file.title}
                </a>
                <div
                  className={styles.changeName}
                  onClick={() => setEditing(true)}
                >
                  Cambiar nombre
                </div>
              </>
            )}
        </div>
      </td>
      <td style={{ textAlign: 'right' }}>{humanFileSize(file.fileSize)}</td>
      <td style={{ textAlign: 'right' }}>
        <FontAwesomeIcon
          icon={faTrash}
          className={styles.fileDelete}
          onClick={onDelete}
        />
      </td>
    </tr>
  );
};

FileRow.defaultProps = {
  onDelete: () => { },
  onSaveChangeTitle: () => { },
};

FileRow.propTypes = {
  file: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  onSaveChangeTitle: PropTypes.func,
};
export default FileRow;
