/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams, Link } from 'react-router-dom';
import isMobile from 'is-mobile';
import moment from 'moment';
import {
  Row, Col,
  Form, Input,
  Button,
  Alert,
  DatePicker,
  Popconfirm,
  Select,
  notification,
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck, faInfoCircle, faQuestion, faTrash,
} from '@fortawesome/free-solid-svg-icons';

import Loading from '../../components/Loading';
import FilesListAndUpload from '../../components/FilesListAndUpload';
import CopyWithMessage from '../../components/CopyWithMessage';

import * as sessionService from '../../services/sessions';
import * as userService from '../../services/users';
import * as customerService from '../../services/customers';

import stylesLayout from '../../common/layout.module.scss';
import stylesForm from '../../common/form.module.scss';
import EventsList from '../../components/EventsList';

import styles from './index.module.scss';

import { sources } from '../../helpers/utils';

const { Option } = Select;

const signs = ['Aries', 'Tauro', 'Géminis', 'Cáncer', 'Leo', 'Virgo', 'Libra', 'Escorpio', 'Sagitario', 'Capricornio', 'Acuario', 'Piscis'].map((x) => ({ label: x, value: x }));

const calendlyStatuses = {
  active: 'Activo',
  cancelled: 'Cancelado',
};

const SessionModule = ({
  user,
}) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { hash, customerHash } = useParams();
  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState([]);

  const [saving, setSaving] = useState(false);
  const [sending, setSending] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const [assigneeUsers, setAssigneeUsers] = useState([]);

  const loadAssigneeUsers = async () => {
    const rsp = await userService.getUsersByRole('sessions');
    if (rsp && rsp.status) {
      setAssigneeUsers(rsp.data);
    }
  };

  const loadSession = async () => {
    if (hash === 'nueva') {
      const rsp2 = await customerService.get(customerHash);
      if (rsp2.status) {
        console.log('rsp2.data', rsp2.data);
        setSession({
          name: rsp2.data.name,
          email: rsp2.data.email,
          phone: rsp2.data.phone,
          sun: rsp2.data.sun,
          moon: rsp2.data.moon,
          source: rsp2.data.source,
          ascendant: rsp2.data.ascendant,
          birthdate: rsp2.data.birthdate,
          birthplace: rsp2.data.birthplace,
          customer_id: rsp2.data.id,
        });
      }
    } else {
      const rsp = await sessionService.get(hash);
      if (rsp && rsp.status) {
        setSession(rsp.data);
      }
    }
  };

  const onSaveItem = async (values) => {
    setSaving(true);
    setError(null);
    const s = {
      ...session,
      ...values,
    };
    if (hash === 'nueva') {
      const rsp = await sessionService.create(s);
      setSaving(false);
      if (rsp && rsp.status) {
        history.replace(`/sesion/${rsp.data.hash}/${rsp.data.customer_hash}`);
      }

      return;
    }

    const rsp = await sessionService.update(s.hash, s);
    if (rsp && !rsp.status) {
      setError(rsp.msg);
    }
    if (rsp && rsp.status) {
      setSaved(true);
    }
    if (rsp && !rsp.status) {
      setError(rsp.msg);
    }
    setSaving(false);
  };

  const onDeleteSession = async () => {
    const rsp = await sessionService.remove(session.hash);
    if (rsp && !rsp.status) {
      setError(rsp.msg);
    }
    if (rsp && rsp.status) {
      notification.open({
        description:
          'Sesión eliminada',
        icon: <FontAwesomeIcon icon={faInfoCircle} color="#093" />,
      });
      window.setTimeout(() => history.replace('/sesiones'), 2000);
      return;
    }
    if (rsp && !rsp.status) {
      setError(rsp.msg);
    }
    setSaving(false);
  };

  const onChangeItem = () => {
    const s = {
      ...session,
      ...form.getFieldsValue(),
    };
    setSaved(false);
    setSession(s);
  };
  const onChangeField = (field, value) => {
    const s = {
      ...session,
      [field]: value,
    };
    setSaved(false);
    setSession(s);
  };

  const sendEmail = async (email) => {
    setSending(true);
    const rsp = await sessionService.sendAccessEmail(session.hash, email);
    setSending(false);
    if (rsp && rsp.status) {
      setSession(rsp.data);
      // eslint-disable-next-line no-alert
      notification.open({
        description:
          `Email de acceso enviado a ${email}`,
        icon: <FontAwesomeIcon icon={faInfoCircle} color="#093" />,
      });
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await Promise.all([
        loadAssigneeUsers(),
        loadSession(true),
      ]);
      setLoading(false);
    })();
  }, [hash]);

  if (loading) {
    return <div className={stylesLayout.page}><Loading /></div>;
  }

  const isNew = (hash === 'nueva');
  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.breadcrumb}>
        <Link to="/sesiones">Sesiones</Link>
        {' '}
        &raquo;
      </div>

      <div className={stylesLayout.title}>
        Sesión
      </div>
      <div className={stylesLayout.actions}>
        {user.is_admin && (
          <>
            <Button
              disabled={sending}
              onClick={() => { sendEmail(user.email); }}
              style={{ whiteSpace: 'normal', height: 'auto', marginBottom: '10px' }}
              block={isMobile()}
            >
              Enviar email de acceso a mi email (
              {user.email}
              )
            </Button>
            {' '}
            <Button
              disabled={sending || !session.email}
              onClick={() => { sendEmail(session.email); }}
              style={{ whiteSpace: 'normal', height: 'auto', marginBottom: '10px' }}
              block={isMobile()}
            >
              Enviar email de acceso a
              {' '}
              {session.name}
            </Button>
            {' '}
            <Popconfirm
              title="¿Confirmas eliminar esta sesión?"
              onConfirm={onDeleteSession}
              okText="Si"
              okButtonProps={{ danger: true }}
              icon={(
                <FontAwesomeIcon
                  icon={faQuestion}
                  style={{
                    position: 'absolute', top: 8.0005, width: 15, color: '#c30',
                  }}
                />
              )}
              cancelText="No"
            >
              <Button
                style={{ whiteSpace: 'normal', height: 'auto', marginBottom: '10px' }}
                block={isMobile()}
                danger
              >
                <FontAwesomeIcon icon={faTrash} style={{ marginRight: 10 }} />
                Eliminar sesión
              </Button>
            </Popconfirm>
          </>
        )}
      </div>
      <div className={`${stylesLayout.content} ${stylesForm.form} ${styles.form}`}>
        <div className={styles.formSidebar}>
          <EventsList events={session?.events || []} />
          {!isNew && session.calendly_id && (
            <>
              <br />
              <br />
              <div className={stylesForm.header}>
                Info en Calendly
              </div>
              <div className={styles.calendly}>
                <div className={styles.calendly_item}>
                  <strong>{session.calendly_data?.name}</strong>
                </div>
                <div className={styles.calendly_item}>
                  <strong>Status</strong>
                  :
                  {' '}
                  {calendlyStatuses[session.calendly_data?.status]}
                </div>
                <div className={styles.calendly_item}>
                  <strong>Zoom URL</strong>
                  :
                  {' '}
                  {session.calendly_data?.location?.join_url
                    ? <a href={session.calendly_data?.location?.join_url} target="_blank" rel="noreferrer">{session.calendly_data?.location?.join_url}</a>
                    : 'No disponible'}
                </div>
                <div className={styles.calendly_item}>
                  <strong>Preguntas y respuestas:</strong>
                  <div className={styles.calendly_questions}>
                    {session.calendly_data?.invitee_data?.questions_and_answers.map((x) => (
                      <div className={styles.calendly_question}>
                        <span className={styles.q}>{x.question}</span>
                        <span className={styles.a}>{x.answer}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={styles.calendly_item}>
                  URL de reagendamiento:
                  {' '}
                  <a href={session.calendly_data?.invitee_data?.reschedule_url} target="_blank" rel="noreferrer">
                    {session.calendly_data?.invitee_data?.reschedule_url}
                  </a>
                </div>
                <div className={styles.calendly_item}>
                  URL de cancelación:
                  {' '}
                  <a href={session.calendly_data?.invitee_data?.cancel_url} target="_blank" rel="noreferrer" style={{ color: '#c30' }}>
                    {session.calendly_data?.invitee_data?.cancel_url}
                  </a>
                </div>
                <br />
                <br />
                <div>
                  <pre style={{ fontSize: 12, fontFamily: 'monospace', wordBreak: 'break-all' }}>
                    {JSON.stringify(session.calendly_data, null, 2)}
                  </pre>
                </div>
              </div>

            </>
          )}
        </div>
        <div className={styles.formMain}>
          {error
            && (
              <div className={stylesForm.error}>
                <Alert message={error} type="error" />
              </div>
            )}

          <Form
            name="basic"
            form={form}
            labelAlign="left"
            initialValues={session}
            layout="horizontal"
            {...{
              labelCol: { span: 6 },
              wrapperCol: { span: 18 },
            }}
            onFinish={onSaveItem}
            onValuesChange={onChangeItem}
            size="large"
          >

            <Row>
              <Col xs={24}>
                <Form.Item
                  label="Nombre"
                  name="name"
                  rules={[{ required: true, message: 'El nombre es requerido' }]}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Form.Item
                  label="Email"
                  name="email"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Form.Item
                  label="Teléfono"
                  name="phone"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Form.Item
                  label="Origen"
                  name="source"
                >
                  <Select
                    placeholder="Selecciona..."
                    style={{ width: '100%' }}
                    options={sources.map((x) => ({ label: x, value: x }))}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Form.Item
                  label="Fecha y hora de nacimiento"
                  {...{
                    labelCol: { span: 12 },
                    wrapperCol: { span: 12 },
                  }}
                >
                  <DatePicker
                    placeholder="Elige fecha..."
                    defaultValue={session.birthdate && moment(session.birthdate)}
                    onChange={(e) => { onChangeField('birthdate', e); }}
                    format="YYYY-MM-DD HH:mm"
                    disabled
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                  />
                </Form.Item>
              </Col>
              <Col sm={12}>
                <Form.Item
                  label="Lugar de nacimiento/cumpleaños"
                  name="birthplace"
                  {...{
                    labelCol: { span: 12 },
                    wrapperCol: { span: 12 },
                  }}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={24}>
                <Form.Item
                  label="Sol"
                  name="sun"
                  {...{
                    labelCol: { span: 6 },
                    wrapperCol: { span: 18 },
                  }}
                >
                  <Select
                    placeholder="Selecciona..."
                    style={{ width: '100%' }}
                    options={signs}
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={24}>
                <Form.Item
                  label="Luna"
                  name="moon"
                  {...{
                    labelCol: { span: 6 },
                    wrapperCol: { span: 18 },
                  }}
                >
                  <Select
                    placeholder="Selecciona..."
                    style={{ width: '100%' }}
                    options={signs}
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={24}>
                <Form.Item
                  label="Ascendente"
                  name="ascendant"
                  {...{
                    labelCol: { span: 6 },
                    wrapperCol: { span: 18 },
                  }}
                >
                  <Select
                    placeholder="Selecciona..."
                    style={{ width: '100%' }}
                    options={signs}
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <Form.Item
                  label="Fecha de la sesión"
                  {...{
                    labelCol: { span: 12 },
                    wrapperCol: { span: 12 },
                  }}
                >
                  <DatePicker
                    placeholder="Elige fecha..."
                    defaultValue={session.date && moment(session.date)}
                    onChange={(e) => { onChangeField('date', e); }}
                    format="YYYY-MM-DD HH:mm"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                  />
                </Form.Item>
              </Col>
              <Col sm={12}>
                <Form.Item
                  label="Tipo de sesión"
                  name="type"
                  {...{
                    labelCol: { span: 12 },
                    wrapperCol: { span: 12 },
                  }}
                >
                  <Select placeholder="Selecciona..." style={{ width: '100%' }}>
                    <Option value="carta_natal">Carta Natal</Option>
                    <Option value="revolucion_solar">Revolución Solar</Option>
                    <Option value="transitos_planetarios">Tránsitos Planetarios</Option>
                    <Option value="database">Base de datos</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col sm={24}>
                <Form.Item
                  label="Asignada a"
                  name="assigned_to"
                >
                  {user.can.assign_sessions
                    ? (
                      <Select placeholder="Selecciona..." style={{ width: '100%' }}>
                        {assigneeUsers.map((u) => <Option value={u.id}>{u.name}</Option>)}
                      </Select>
                    )

                    : <span>{session.assignee?.name}</span>}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Form.Item
                  label="Comentarios internos"
                  name="private_comments"
                >
                  <Input.TextArea />
                </Form.Item>
              </Col>
            </Row>

            {!isNew && (
              <>
                <Row>
                  <Col xs={24}>
                    <Form.Item
                      label="Ver"
                      style={{ lineHeight: '14px' }}
                    >
                      <div
                        style={{
                          display: 'inline-block', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: 'auto', maxWidth: '80% ',
                        }}
                      >
                        <a
                          href={`https://site.almaceleste.cl/sesion/${hash}`}
                          target="_ac_session"
                          className={stylesLayout.link}
                          role="button"
                          tabIndex={0}
                          style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            maxWidth: 300,
                            display: 'inline-block',
                          }}
                        >
                          {`https://site.almaceleste.cl/sesion/${hash}`}
                        </a>
                      </div>
                      <div style={{ display: 'inline-block', marginLeft: 10 }}>
                        <CopyWithMessage value={`https://site.almaceleste.cl/sesion/${hash}`} />
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            <Row style={{ marginBottom: 30 }}>
              <Col offset={4} span={20} xs={{ offset: 0, span: 24 }}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" disabled={saving}>
                    Guardar
                  </Button>

                  {saving && <span className={stylesForm.saveMessage}>Guardando...</span>}
                  {!saving && saved && (
                    <span className={`${stylesForm.saveMessage} ${stylesForm.success}`}>
                      <FontAwesomeIcon icon={faCheck} />
                      {' '}
                      Guardado
                    </span>
                  )}
                </Form.Item>
              </Col>
            </Row>

            {hash !== 'nueva' && (
              <>
                <FilesListAndUpload
                  files={session.files}
                  folderId={session.data?.gdrive_folder_id}
                  onUploadComplete={() => { loadSession(false); }}
                  onDeleteComplete={() => { loadSession(false); }}
                  onSaveChangeTitleComplete={() => { loadSession(false); }}
                />
              </>
            )}

          </Form>
        </div>
      </div>
    </div>
  );
};

SessionModule.propTypes = {
  user: PropTypes.object.isRequired,
};

export default SessionModule;
